var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "footer" }, [
    _vm._v(" 主办方：" + _vm._s(_vm.shortName) + " "),
    _c("span", { staticClass: "line" }, [_vm._v("|")]),
    _vm._v(" 本网站由"),
    _c(
      "a",
      {
        staticStyle: { color: "#717171" },
        attrs: { href: "https://www.iguokao.com", target: "view_window" },
      },
      [_vm._v("国考云")]
    ),
    _vm._v("考试系统提供技术支持 "),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }