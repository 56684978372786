/*
 * @Author: Libra
 * @Date: 2021-08-06 16:24:30
 * @LastEditTime: 2021-08-06 16:29:34
 * @LastEditors: Libra
 * @Description: sessionStorage
 * @FilePath: /stone-exam-ui/src/utils/storage.js
 */
export function getItem(key) {
  return sessionStorage[key]
}
export function setItem(key, value) {
  sessionStorage[key] = value
}
export function removeItem(key) {
  sessionStorage.removeItem(key)
}
export function clearAll() {
  sessionStorage.clear()
}
