/*
 * @Author: Libra
 * @Date: 2021-04-27 10:59:54
 * @LastEditTime: 2021-05-06 16:17:49
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-exam-ui/src/utils/auth.js
 */
import Cookies from 'js-cookie'

const TokenKey = 'candidate-token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
