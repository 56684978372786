/*
 * @Author: Libra
 * @Date: 2021-08-06 14:45:38
 * @LastEditTime: 2023-04-21 18:39:46
 * @LastEditors: Libra
 * @Description: 监控信息 api
 * @FilePath: /stone-exam-ui/src/api/monitor/index.js
 */
import request from '@/utils/request'
import config from '../config'

/**
 * 考生离开页面
 * @returns
 */
function leave(data) {
  return request({
    url: `${config.EXAM}/monitor/action/leave`,
    method: 'post',
    data: data
  })
}
/**
 * 考生跳出页面次数
 * @returns request
 */
function leaveCount() {
  return request({
    url: `${config.EXAM}/monitor/leave/count`,
    method: 'get'
  })
}
/**
 * 获取手机占屏状态
 */
function getOccupyStatus() {
  return request({
    url: `${config.EXAM}/monitor/mobile/holding`,
    method: 'get'
  })
}
/**
 * 获取短token，用于手机扫码
 */
function getMobileToken() {
  return request({
    url: `${config.EXAM}/monitor/mobile/token/gen`,
    method: 'get'
  })
}
/**
 * 获取 OSS 监控上传的 Token
 * @returns token
 */
function ossMonitorToken() {
  return request({
    url: `${config.EXAM}/monitor/oss/monitor/token`,
    method: 'get'
  })
}

// 检查手机推流状态
function checkMobile() {
  return request({
    url: `${config.EXAM}/trtc/mobile/check`,
    method: 'get'
  })
}
/**
 * 获取 Oss 常规上传的 Token
 * @returns token
 */
function ossToken() {
  return request({
    url: `${config.EXAM}/monitor/oss/exam/token`,
    method: 'get'
  })
}
// 获取每种type最后一条上传记录
function getQuestionSlice(questionUuid) {
  return request({
    url: `${config.EXAM}/monitor/slice/question/${questionUuid}`,
    method: 'get'
  })
}
/**
 * /api/monitor/slice/last/{type} 获取最后一条记录
 * @returns request
 */
function getLastSliceByType(type) {
  return request({
    url: `${config.EXAM}/monitor/slice/last/${type}`,
    method: 'get'
  })
}
// 删除切片
function deleteSlice(data) {
  return request({
    url: `${config.EXAM}/monitor/slice/remove`,
    method: 'post',
    data: data
  })
}
/**
  更新切片信息
  data.type:
    CAMERA_IMAGE(1, "摄像头拍照"),
    CAMERA_VIDEO(2, "摄像头视频"),
    MOBILE_VIDEO(3, "手机视频"),
    SCREEN_SHOT(4, "屏幕抓图"),
    SCREEN_VIDEO(5, "屏幕录像"),
    ANSWER_UPLOAD(6, "考生答案上传"),
    FACE_DIFF(7, "人脸对比"),
    CAMERA_IMAGE_15_SECOND(8, "摄像头拍照 15秒"),
    SCREEN_SHOT_LEAVE(9, "考生离开拍照"),
*/
function updateSlice(data) {
  return request({
    url: `${config.EXAM}/monitor/slice/upload`,
    method: 'post',
    data: data
  })
}
function ossUploadFile(data, onUploadProgress) {
  const formData = new FormData()
  formData.append('name', data.filename)
  formData.append('key', `${data.filename}`)
  formData.append('OSSAccessKeyId', data.ossInfo.accessid)
  formData.append('policy', data.ossInfo.policy)
  formData.append('signature', data.ossInfo.signature)
  formData.append('file', data.blob)
  return request({
    url: data.ossInfo.host,
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress
  })
}

// 根据短token获取token
function getTokenByShortToken(token) {
  return request({
    url: `${config.EXAM}/monitor/mobile/token/get/${token}`,
    method: 'get'
  })
}

// 获取 视频监控 token (声网)
export function getCameraToken() {
  return request({
    url: `${config.EXAM}/monitor/agora/c/token`,
    method: 'get'
  })
}
// 获取 屏幕录制监控 token (声网)
export function getScreenToken() {
  return request({
    url: `${config.EXAM}/monitor/agora/s/token`,
    method: 'get'
  })
}
// 云端录制  开始录制 (声网)
export function startRecord(data) {
  return request({
    url: `${config.EXAM}/monitor/agora/start`,
    method: 'post',
    data
  })
}

// 云端录制  停止录制 (声网)
export function stopRecord(data) {
  return request({
    url: `${config.EXAM}/monitor/agora/stop`,
    method: 'post',
    data
  })
}

// 获取 视频监控 token (腾讯)
export function getCameraSig() {
  return request({
    url: `${config.EXAM}/trtc/camera/sig`,
    method: 'get'
  })
}
// 获取 屏幕监控 token (腾讯)
export function getScreenSig() {
  return request({
    url: `${config.EXAM}/trtc/screen/sig`,
    method: 'get'
  })
}
export function getQuestionSig(questionUuid) {
  return request({
    url: `${config.EXAM}/trtc/question/sig/${questionUuid}`,
    method: 'get'
  })
}
export function addAiAction(data) {
  return request({
    url: `${config.EXAM}/monitor/ai/warning`,
    method: 'post',
    data
  })
}
export default {
  ossToken,
  ossMonitorToken,
  updateSlice,
  deleteSlice,
  getQuestionSlice,
  getLastSliceByType,
  leaveCount,
  leave,
  getMobileToken,
  getOccupyStatus,
  ossUploadFile,
  getTokenByShortToken,
  checkMobile,
  getCameraToken,
  getScreenToken,
  startRecord,
  stopRecord,
  getCameraSig,
  getScreenSig,
  getQuestionSig,
  addAiAction
}
