/*
 * @Author: Libra
 * @Date: 2021-04-27 10:59:54
 * @LastEditTime: 2023-10-30 17:56:47
 * @LastEditors: Libra
 * @Description: config 配置
 * @FilePath: /stone-exam-ui/src/api/config.js
 */
const dev_url = 'https://api.igky.cc/'
const dev_file_url = 'https://exam-file.igky.cc/'
const sys_dev_file_url = 'http://exam-file.igky.cc/'
const dev_service = {
  EXAM: `${dev_url}exam/api`,
  OJ: `${dev_url}oj/api`
}

const exam_prod_url = 'https://exam-api.iguokao.com/'
const oj_prod_url = 'https://oj-api.iguokao.com/'
const pro_file_url = 'https://exam-file.iguokao.com/'
const sys_pro_file_url = 'https://exam-file.iguokao.com/'
const prod_server = {
  EXAM: `${exam_prod_url}api`,
  OJ: `${oj_prod_url}api`
}
let export_server = {}
let file_host = ''
let sys_file_host = ''

switch (process.env.NODE_ENV) {
  case 'development':
    export_server = dev_service
    file_host = dev_file_url
    sys_file_host = sys_dev_file_url
    break
  case 'production':
    export_server = prod_server
    file_host = pro_file_url
    sys_file_host = sys_pro_file_url
    break
  case 'beta':
    export_server = dev_service
    file_host = dev_file_url
    sys_file_host = sys_dev_file_url
    break
  default:
    break
}
export { file_host, sys_file_host }
export default export_server
