var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.init
    ? _c(
        "div",
        {
          staticClass: "main-layout",
          class: _vm.$route.name === "Question" ? "" : "common-bg",
        },
        [
          _c(
            "GlobalDialog",
            {
              attrs: {
                dialogVisible: _vm.showOneToOneDialog,
                show_close: false,
                title: "重要提示",
                width: "500px",
                btn_title: "我知道了",
              },
              on: {
                "dialog-cancel": function ($event) {
                  _vm.showOneToOneDialog = false
                },
              },
            },
            [
              _c("div", { staticClass: "dialog-container" }, [
                _c("div", { staticClass: "content" }, [
                  _c("i", {
                    staticClass:
                      "iconfont iconyanjizhushou-shangchuan_yangshengqi",
                  }),
                  _c("div", { staticClass: "right" }, [
                    _c("span", {
                      staticClass: "title",
                      domProps: { innerHTML: _vm._s(_vm.perMsg) },
                    }),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "GlobalDialog",
            {
              attrs: {
                dialogVisible: _vm.showPropaganda,
                show_close: false,
                title: "考官通话",
                width: "500px",
                btn_title: "我知道了",
              },
              on: {
                "dialog-cancel": function ($event) {
                  _vm.showPropaganda = false
                },
              },
            },
            [
              _c("div", { staticClass: "dialog-container" }, [
                _c("div", { staticClass: "content2" }, [
                  _c("i", { staticClass: "iconfont icontishi" }),
                  _c("div", { staticClass: "right" }, [
                    _c(
                      "span",
                      {
                        staticClass: "title",
                        staticStyle: { "font-size": "20px", color: "#cb2a1d" },
                      },
                      [_vm._v("监考官将与您通话！")]
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "GlobalDialog",
            {
              attrs: {
                dialogVisible: _vm.showYellowCardDialog,
                show_close: false,
                title: "重要提示",
                width: "500px",
                btn_title: "我知道了",
              },
              on: {
                "dialog-cancel": function ($event) {
                  _vm.showYellowCardDialog = false
                },
              },
            },
            [
              _c("div", { staticClass: "dialog-container" }, [
                _c(
                  "div",
                  {
                    staticClass: "content",
                    staticStyle: { padding: "0 20px" },
                  },
                  [
                    _c("img", {
                      staticClass: "card",
                      attrs: {
                        src: require("../svgIcon/svg/yellow-card.svg"),
                        alt: "",
                      },
                    }),
                    _c("div", { staticClass: "right" }, [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "22px",
                            color: "red",
                            "font-weight": "bold",
                            "margin-bottom": "10px",
                          },
                        },
                        [_vm._v("黄牌警告")]
                      ),
                      _c("span", {
                        staticClass: "title",
                        domProps: { innerHTML: _vm._s(_vm.perMsg) },
                      }),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
          _c(
            "GlobalDialog",
            {
              attrs: {
                dialogVisible: _vm.showRedCardDialog,
                show_close: false,
                title: "重要提示",
                width: "500px",
                btn_title: "我知道了",
              },
              on: {
                "dialog-cancel": function ($event) {
                  _vm.showRedCardDialog = false
                },
              },
            },
            [
              _c("div", { staticClass: "dialog-container" }, [
                _c(
                  "div",
                  {
                    staticClass: "content",
                    staticStyle: { padding: "0 20px" },
                  },
                  [
                    _c("img", {
                      staticClass: "card",
                      attrs: {
                        src: require("../svgIcon/svg/red-card.svg"),
                        alt: "",
                      },
                    }),
                    _c("div", { staticClass: "right" }, [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "22px",
                            color: "red",
                            "font-weight": "bold",
                            "margin-bottom": "10px",
                          },
                        },
                        [_vm._v("红牌警告")]
                      ),
                      _c("span", {
                        staticClass: "title",
                        domProps: { innerHTML: _vm._s(_vm.perMsg) },
                      }),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
          _c(
            "GlobalDialog",
            {
              attrs: {
                dialogVisible: _vm.showPhonePositionDialog,
                show_close: false,
                title: "手机监控规范摆放示意图",
                width: "700px",
                btn_title: "我知道了",
              },
              on: {
                "dialog-cancel": function ($event) {
                  _vm.showPhonePositionDialog = false
                },
              },
            },
            [
              _c("div", { staticClass: "dialog-container" }, [
                _c("span", { staticClass: "img-title" }, [
                  _vm._v("你的手机摆放不规范！请参见下图摆放。"),
                ]),
                _c("img", {
                  attrs: {
                    src: require("../assets/images/phonePosition.png"),
                    alt: "",
                  },
                }),
              ]),
            ]
          ),
          _c(
            "GlobalDialog",
            {
              attrs: {
                dialogVisible: _vm.showExtendDialog,
                show_close: false,
                title: "考试延长",
                width: "500px",
              },
              on: {
                "dialog-cancel": function ($event) {
                  _vm.showExtendDialog = false
                },
              },
            },
            [
              _c("div", { staticClass: "dialog-container" }, [
                _c("div", { staticClass: "content" }, [
                  _c("i", { staticClass: "iconfont icontishi" }),
                  _c("div", { staticClass: "right" }, [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("考试延长" + _vm._s(_vm.extendTime) + " 分钟"),
                    ]),
                    _c("span", { staticClass: "detail" }, [
                      _vm._v("在此时间内可继续做答!!"),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm.$route.name !== "Question" && _vm.$route.name !== "Complete"
            ? _c(
                "el-badge",
                { staticClass: "badge", attrs: { "is-dot": _vm.unReadCounts } },
                [
                  _c(
                    "div",
                    { staticClass: "service", on: { click: _vm.openCustomer } },
                    [_c("i", { staticClass: "iconfont iconkefu" })]
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showCustomer,
                  expression: "showCustomer",
                },
              ],
              staticClass: "service-box",
            },
            [
              _c("customer", {
                ref: "customer",
                attrs: { isOpen: _vm.showCustomer },
                on: {
                  messageList: _vm.handleMessageList,
                  closeCustomer: function ($event) {
                    _vm.showCustomer = false
                  },
                },
              }),
            ],
            1
          ),
          _c("new-message", {
            ref: "broadcast",
            attrs: { content: _vm.content },
          }),
          _c("Message"),
          _vm.$route.name !== "Complete" &&
          _vm.$route.name !== "DeviceDetection"
            ? _c("simple-header", { attrs: { companyInfo: _vm.companyInfo } })
            : _vm._e(),
          _vm.$route.name !== "Question" &&
          _vm.$route.name !== "Complete" &&
          _vm.$route.name !== "DeviceDetection"
            ? _c("div", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.companyName +
                        _vm.$store.state.examInfo.userExamName +
                        _vm.$store.state.jobInfo.name
                    ) +
                    " "
                ),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-weight": "normal",
                      "font-size": "14px",
                      color: "#888",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$store.state.examInfo.scenario))]
                ),
              ])
            : _vm._e(),
          _vm._t("default"),
          _vm.$route.name !== "Question" && _vm.$route.name !== "Complete"
            ? _c("simple-footer", {
                attrs: { shortName: _vm.companyInfo.companyShortName },
              })
            : _vm._e(),
        ],
        2
      )
    : _c("div", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.init,
            expression: "init",
          },
        ],
        staticClass: "loading",
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }