/*
 * @Author: Libra
 * @Date: 2021-08-06 14:43:04
 * @LastEditTime: 2021-08-06 15:38:15
 * @LastEditors: Libra
 * @Description: 消息广播 api
 * @FilePath: /stone-exam-ui/src/api/message/index.js
 */

import request from '@/utils/request'
import config from '../config'

/**
 * 获取公告
 */
function getBroadcast() {
  return request({
    url: `${config.EXAM}/message/broadcast`,
    method: 'post'
  })
}
/**
 * 发送消息
 * @returns
 */
function sendMessage(data) {
  return request({
    url: `${config.EXAM}/message/send`,
    method: 'post',
    data: data
  })
}
/**
 * 消息已读
 * @returns
 */
function messageRead() {
  return request({
    url: `${config.EXAM}/message/read`,
    method: 'post'
  })
}
/**
 * 获取消息列表
 * @returns
 */
function getMessageList(data) {
  return request({
    url: `${config.EXAM}/message/list`,
    method: 'post',
    data: data
  })
}
export default {
  sendMessage,
  messageRead,
  getMessageList,
  getBroadcast
}
