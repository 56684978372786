<template>
  <div>
    <GlobalDialog
      :dialogVisible="showMqttDisConnect"
      @dialog-cancel="reConnectMqtt"
      title="提示"
      btn_title="重新连接"
      width="500px"
    >
      <div class="camera-snap-container">
        <div class="content">
          <i class="iconfont icontishi"></i>
          <div class="right">
            <span
class="title"
              >检测到您断开连接</span
            >
            <span class="detail">请点击下方按钮重新尝试！！</span>
          </div>
        </div>
      </div>
    </GlobalDialog>
  </div>
</template>

<script>
import mqtt from 'mqtt'
import { bus } from '../../utils/bus'
import GlobalDialog from '@/components/GlobalDialog'
export default {
  data() {
    return {
      msgContent: null,
      client: {
        connected: false
      },
      receiveNews: '',
      subscribeSuccess: false,
      connecting: false,
      showMqttDisConnect: false,
      retryTimes: 0,
      subscriptions: [
        {
          topic:
            process.env.NODE_ENV === 'production'
              ? `exam/msg/${this.$store.state.userInfo.candidateUuid}`
              : `t/exam/msg/${this.$store.state.userInfo.candidateUuid}`,
          qos: 0
        },
        {
          topic:
            process.env.NODE_ENV === 'production'
              ? `exam/bc/${this.$store.state.jobInfo.jobUuid}`
              : `t/exam/bc/${this.$store.state.jobInfo.jobUuid}`,
          qos: 0
        }
      ]
    }
  },
  components: {
    GlobalDialog
  },
  mounted() {
    this.createConnection()
    this.doSubscribe()
    bus.$on('destoryMsg', () => {
      this.destroyConnection()
    })
    this.$once('hook:beforeDestroy', () => {
      bus.$off('destoryMsg')
    })
  },
  methods: {
    sliceFn(length) {
      let str = Math.random() // 0.287365152732
      str = str.toString() // '0.287365152732'
      str = str.slice(2, 2 + length) // '287365'
      return str
    },
    // 创建连接
    createConnection() {
      const password = this.sliceFn(6)
      const { candidateUuid } = this.$store.state.userInfo
      const isProd = process.env.NODE_ENV === 'production'
      const connection = {
        host: 'broker.iguokao.com',
        port: 8084,
        endpoint: '/mqtt',
        clean: true, // 保留会话
        connectTimeout: 30 * 1000, // 超时时间
        reconnectPeriod: 4000, // 重连时间间隔
        // 认证信息
        clientId: isProd ? `e_${candidateUuid}` : `t_e_${candidateUuid}`,
        username: isProd ? candidateUuid : `t_${candidateUuid}`,
        password
      }
      try {
        this.connecting = true
        const { host, port, endpoint, ...options } = connection
        const connectUrl = `wss://${host}:${port}${endpoint}`
        this.client = mqtt.connect(connectUrl, options)
        if (!this.client) return
        this.client.on('connect', () => {
          this.connecting = false
          console.log('Connection succeeded!')
        })
        this.client.on('error', (error) => {
          if (this.retryTimes > 3) {
            this.client.end()
            this.initData()
          }
          console.log('Connection failed', error)
        })
        this.client.on('reconnect', this.handleOnReConnect)
        this.client.on('message', async(topic, message) => {
          this.receiveNews = this.receiveNews.concat(message)
          console.log(`Received message ${message} from topic ${topic}`)
          message = JSON.parse(message)
          bus.$emit('message', message)
        })
      } catch (error) {
        this.connecting = false
        console.log('mqtt.connect error', error)
      }
    },
    initData() {
      this.client = {
        connected: false
      }
      this.retryTimes = 0
      this.connecting = false
      this.subscribeSuccess = false
    },
    handleOnReConnect() {
      this.retryTimes += 1
      if (this.retryTimes > 5) {
        try {
          this.client.end()
          this.initData()
          this.$message.error('MQTT连接失败，请刷新页面重试')
          this.showMqttDisConnect = true
        } catch (error) {
          this.$message.error(error.toString())
        }
      }
    },
    reConnectMqtt() {
      this.showMqttDisConnect = false
      this.createConnection()
      this.doSubscribe()
    },
    // 订阅主题
    doSubscribe() {
      if (!this.client) return
      for (const item of this.subscriptions) {
        this.client.subscribe(item.topic, { qos: item.qos }, (error, res) => {
          if (error) {
            console.log('Subscribe to topics error', error)
            return
          }
          console.log('Subscribe to topics res', res)
        })
      }
    },
    // 取消订阅
    doUnSubscribe() {
      if (!this.client) return
      for (const item of this.subscriptions) {
        this.client.unsubscribe(item.topic, (error) => {
          if (error) {
            console.log('Unsubscribe error', error)
          }
        })
      }
    },
    doPublish(publish) {
      const { topic, qos, payload } = publish
      if (!this.client) return
      this.client.publish(topic, payload, qos, (error) => {
        if (error) {
          console.log('Publish error', error)
        }
      })
    },
    // 断开链接
    destroyConnection() {
      if (!this.client) return
      console.log(this.client.connected)
      if (this.client.connected) {
        try {
          this.client.end()
          this.client.connected = false
          console.log('Successfully disconnected!')
        } catch (error) {
          console.log('Disconnect failed', error.toString())
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
 .camera-snap-container {
    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      .iconfont {
        font-size: 100px;
        color: #cb2a1d;
        margin-right: 20px;
      }
      .right {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        .title {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 20px;
        }
      }
    }
    .screen-share {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 0 20px;
      .title {
        color: #cb2a1d;
        font-size: 16px;
      }
    }
  }
</style>
