/*
 * @Author: Libra
 * @Date: 2021-08-06 15:28:54
 * @LastEditTime: 2021-08-06 15:38:25
 * @LastEditors: Libra
 * @Description: OJ api
 * @FilePath: /stone-exam-ui/src/api/oj/index.js
 */
import request from '@/utils/request'
import config from '../config'

/**
 * 运行代码
 * @returns
 */
function runCode(data) {
  return request({
    url: `${config.OJ}/code/run`,
    method: 'post',
    data: data
  })
}
/**
 * 获取支持的语言
 */
function getLanguage() {
  return request({
    url: `${config.OJ}/code/language`,
    method: 'get'
  })
}
/**
 * 获取代码运行结果
 */
function getRunResult(codeUuid) {
  return request({
    url: `${config.OJ}/code/result/${codeUuid}`,
    method: 'get'
  })
}
/**
 * 获取所有用例结果
 */
function getAllResult(questionUuid) {
  return request({
    url: `${config.OJ}/code/result/list/${questionUuid}`,
    method: 'get'
  })
}
/**
 * 运行用户用例代码
 * @returns
 */
function runUserCode(data) {
  return request({
    url: `${config.OJ}/code/run/user`,
    method: 'post',
    data: data
  })
}
/**
 * 添加测试用例
 * @returns
 */
function addTestCase(data) {
  return request({
    url: `${config.OJ}/remote/testcase/add`,
    method: 'post',
    data: data
  })
}
/**
 * 获取某题测试用例
 * @returns
 */
function getQuestionTestCase(data) {
  return request({
    url: `${config.OJ}/remote/testcase/info`,
    method: 'post',
    data: data
  })
}
export default {
  runCode,
  getLanguage,
  getRunResult,
  getAllResult,
  runUserCode,
  addTestCase,
  getQuestionTestCase
}
