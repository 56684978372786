/*
 * @Author: Libra
 * @Date: 2022-08-19 10:42:15
 * @LastEditTime: 2022-08-19 10:43:39
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-exam-ui/src/api/mobile/index.js
 */
/*
 * @Author: Libra
 * @Date: 2021-12-27 19:46:07
 * @LastEditTime: 2022-01-21 10:40:40
 * @LastEditors: Libra
 * @Description:rtc api
 * @FilePath: /stone-exam-ui/src/api/rtc/index.js
 */
import request from '@/utils/request'
import config from '../config'

// 获取 rtc token
export function getQrToken(candidateUuid) {
  return request({
    url: `${config.EXAM}/candidate/qr/token/${candidateUuid}`,
    method: 'get'
  })
}
export default {
  getQrToken
}
