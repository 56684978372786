/*
 * @Author: Libra
 * @Date: 2021-10-15 13:10:41
 * @LastEditTime: 2022-11-16 10:41:57
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-exam-ui/src/main.js
 */
import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import router from './router'
import store from './store'
import 'normalize.css'
import '@/theme/main.scss'
import '@/theme/reset.scss'
import elePlugin from './common/element'
import VueCodeMirror from 'vue-codemirror'
import './theme/element-variables.scss'
import 'codemirror/lib/codemirror.css'
import VueIntro from 'vue-introjs'
import 'intro.js/introjs.css'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import './icons'
import './directive/click'

Vue.prototype.$video = Video

Vue.use(VueIntro)
Vue.use(VueCodeMirror)
Vue.use(elePlugin)
Vue.config.productionTip = false
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
