<!--
 * @Author: Libra
 * @Date: 2022-04-07 18:03:22
 * @LastEditTime: 2022-11-15 15:13:16
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-exam-ui/src/components/iconSvg/index.vue
-->
<template>
  <a :title="svgTitle">
    <svg :class="svgClass" aria-hidden="true">
      <use :xlink:href="iconName"></use>
    </svg>
  </a>
</template>

<script type="text/ecmascript-6">
export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String
    },
    svgTitle: {
      type: String
    }
  },
  computed: {
    iconName() {
      return `#icon-${this.iconClass}`
    },
    svgClass() {
      if (this.className) {
        return 'svg-icon ' + this.className
      } else {
        return 'svg-icon'
      }
    }
  }
}
</script>

<style lang="scss">
@import './index.scss';
</style>
