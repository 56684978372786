/*
 * @Author: Libra
 * @Date: 2021-08-06 15:01:16
 * @LastEditTime: 2024-04-12 10:30:20
 * @LastEditors: Libra
 * @Description: 考试 api
 * @FilePath: /stone-exam-ui/src/api/exam/index.js
 */
import request from '@/utils/request'
import config from '../config'

// 提交答案
function answerSave(data) {
  return request({
    url: `${config.EXAM}/exam/answer`,
    method: 'post',
    data: data
  })
}
// 删除某个题的答案
function deleteAnswer(questionUuid) {
  return request({
    url: `${config.EXAM}/exam/answer/remove/${questionUuid}`,
    method: 'get'
  })
}
// 获取某个题的答案
function getAnswer(questionUuid) {
  return request({
    url: `${config.EXAM}/exam/answer/${questionUuid}`,
    method: 'get'
  })
}
/**
 * 获取企业信息
 */
function getCompanyName(data) {
  return request({
    url: `${config.EXAM}/exam/company`,
    method: 'post',
    data
  })
}
/**
 * 考试结束
 */
function examFinish() {
  return request({
    url: `${config.EXAM}/exam/finish`,
    method: 'get'
  })
}
/**
 * 考试结束强制结束
 */
function examFinishForce() {
  return request({
    url: `${config.EXAM}/exam/finish/force`,
    method: 'get'
  })
}
/**
 * 获取考试场次信息
 * @returns 场次信息
 */
function examInfo() {
  return request({
    url: `${config.EXAM}/exam/info`,
    method: 'get'
  })
}
// 获取shortname和logo
function getNameAndLogo(data) {
  return request({
    url: `${config.EXAM}/exam/info/simple`,
    method: 'post',
    data: data
  })
}
/**
 * 获取job信息
 * @returns job信息
 */
function jobInfo() {
  return request({
    url: `${config.EXAM}/exam/job/info`,
    method: 'get'
  })
}
/**
 *
 * @param {*} data
 * @returns
 * 获取子卷详情
 */
function getPartInfo(partUuid) {
  return request({
    url: `${config.EXAM}/exam/part/${partUuid}`,
    method: 'get'
  })
}
/**
 * 子卷提交记录
 * @param {String} partUuid
 * @returns {}
 */
function partCommitLog(partUuid) {
  return request({
    url: `${config.EXAM}/exam/part/answered/list/${partUuid}`,
    method: 'get'
  })
}
/**
 * 子卷结束
 * @param {String} partUuid
 * @returns {}
 */
function partFinish(partUuid) {
  return request({
    url: `${config.EXAM}/exam/part/finish/${partUuid}`,
    method: 'get'
  })
}
// 子卷列表
function paperList() {
  return request({
    url: `${config.EXAM}/exam/part/list `,
    method: 'get'
  })
}
// 练习子卷列表
function paperPraticeList() {
  return request({
    url: `${config.EXAM}/exam/practice/part/list `,
    method: 'get'
  })
}
/**
 * 子卷进入
 * @param {String} partUuid
 * @returns {}
 */
function partEnter(partUuid) {
  return request({
    url: `${config.EXAM}/exam/part/start/${partUuid}`,
    method: 'get'
  })
}
/**
 * 子卷计时
 */
function getPartTime(partUuid) {
  return request({
    url: `${config.EXAM}/exam/part/time/${partUuid}`,
    method: 'get'
  })
}
/**
 * 练习题进入
 */
function partPracticeStart() {
  return request({
    url: `${config.EXAM}/exam/practice/start`,
    method: 'get'
  })
}
/*
 * 小卷试题
 * {
 *  "paperUuid": "string", // paper id
 *  "positionUuid": "string" //
 * }
 * */
function paperQuestionList(questionUuid) {
  return request({
    url: `${config.EXAM}/exam/question/${questionUuid}`,
    method: 'get'
  })
}
/**
 * 删除oss文件
 */
function deleteOssFile(data) {
  return request({
    url: `${config.EXAM}/exam/remove/file`,
    method: 'post',
    data
  })
}
// 考试开始
function examStart() {
  return request({
    url: `${config.EXAM}/exam/start`,
    method: 'get'
  })
}
/**
 * 获取时间
 * @returns request
 */
function getTime() {
  return request({
    url: `${config.EXAM}/exam/time`,
    method: 'get'
  })
}
function getTimeByExamId(examId) {
  return request({
    url: `${config.EXAM}/exam/time/${examId}`,
    method: 'get'
  })
}
function getABPaper() {
  return request({
    url: `${config.EXAM}/exam/group/list`,
    method: 'get'
  })
}
function selectABPaper(groupKey) {
  return request({
    url: `${config.EXAM}/exam/group/select/${groupKey}`,
    method: 'get'
  })
}
// 获取某个题的剩余播放次数
function getPlayCount(questionUuid) {
  return request({
    url: `${config.EXAM}/exam/play/times/${questionUuid}`,
    method: 'get'
  })
}
// 某个题的剩余播放次数-1
function subPlayCount(questionUuid) {
  return request({
    url: `${config.EXAM}/exam/play/${questionUuid}`,
    method: 'get'
  })
}
// 获取成绩
function getScore() {
  return request({
    url: `${config.EXAM}/exam/objective/score`,
    method: 'get'
  })
}
// 获取未作答题目的id
function getNotAnsweredList(partUuid) {
  return request({
    url: `${config.EXAM}/exam/part/not/finish/${partUuid}`,
    method: 'get'
  })
}
// 根据简历编号获取试卷列表
function getResumePaperList(data) {
  return request({
    url: `${config.EXAM}/exam/select/exam/list`,
    method: 'post',
    data
  })
}

export default {
  paperList,
  paperPraticeList,
  paperQuestionList,
  examStart,
  answerSave,
  examInfo,
  jobInfo,
  partCommitLog,
  partFinish,
  getTime,
  getTimeByExamId,
  getPartInfo,
  examFinish,
  examFinishForce,
  getNameAndLogo,
  getCompanyName,
  getPartTime,
  partEnter,
  partPracticeStart,
  deleteOssFile,
  getABPaper,
  selectABPaper,
  getPlayCount,
  subPlayCount,
  deleteAnswer,
  getAnswer,
  getScore,
  getNotAnsweredList,
  getResumePaperList
}
