<!--
 * @Author: Libra
 * @Date: 2022-11-10 14:36:00
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-exam-ui/src/layout/index.vue
-->
<template>
  <div class="main-layout" :class="$route.name==='Question' ? '': 'common-bg'" v-if="init">
    <GlobalDialog
      :dialogVisible="showOneToOneDialog"
      @dialog-cancel="showOneToOneDialog = false"
      :show_close="false"
      title="重要提示"
      width="500px"
      btn_title="我知道了"
    >
      <div class="dialog-container">
        <div class="content">
          <i class="iconfont iconyanjizhushou-shangchuan_yangshengqi"></i>
          <div class="right">
            <span class="title" v-html="perMsg"></span>
          </div>
        </div>
      </div>
    </GlobalDialog>
    <GlobalDialog
      :dialogVisible="showPropaganda"
      @dialog-cancel="showPropaganda = false"
      :show_close="false"
      title="考官通话"
      width="500px"
      btn_title="我知道了"
    >
      <div class="dialog-container">
          <div class="content2">
            <i class="iconfont icontishi"></i>
          <div class="right">
            <span class="title" style="font-size: 20px; color: #cb2a1d">监考官将与您通话！</span>
        </div>
          </div>
      </div>
    </GlobalDialog>
    <GlobalDialog
      :dialogVisible="showYellowCardDialog"
      @dialog-cancel="showYellowCardDialog = false"
      :show_close="false"
      title="重要提示"
      width="500px"
      btn_title="我知道了"
    >
      <div class="dialog-container">
        <div class="content" style="padding: 0 20px;">
          <img class="card" src="../svgIcon/svg/yellow-card.svg" alt="" />
          <div class="right">
            <span
              style="font-size: 22px; color: red; font-weight: bold; margin-bottom: 10px;"
              >黄牌警告</span
            >
            <span class="title" v-html="perMsg"></span>
          </div>
        </div>
      </div>
    </GlobalDialog>
    <GlobalDialog
      :dialogVisible="showRedCardDialog"
      @dialog-cancel="showRedCardDialog = false"
      :show_close="false"
      title="重要提示"
      width="500px"
      btn_title="我知道了"
    >
      <div class="dialog-container">
        <div class="content" style="padding: 0 20px;">
          <img class="card" src="../svgIcon/svg/red-card.svg" alt="" />
          <div class="right">
            <span
              style="font-size: 22px; color: red; font-weight: bold; margin-bottom: 10px;"
              >红牌警告</span
            >
            <span class="title" v-html="perMsg"></span>
          </div>
        </div>
      </div>
    </GlobalDialog>
    <GlobalDialog
      :dialogVisible="showPhonePositionDialog"
      @dialog-cancel="showPhonePositionDialog = false"
      :show_close="false"
      title="手机监控规范摆放示意图"
      width="700px"
      btn_title="我知道了"
    >
      <div class="dialog-container">
        <span class="img-title">你的手机摆放不规范！请参见下图摆放。</span>
        <img src="../assets/images/phonePosition.png" alt="" />
      </div>
    </GlobalDialog>
    <GlobalDialog
      @dialog-cancel="showExtendDialog = false"
      :dialogVisible="showExtendDialog"
      :show_close="false"
      title="考试延长"
      width="500px"
    >
      <div class="dialog-container">
        <div class="content">
          <i class="iconfont icontishi"></i>
          <div class="right">
            <span
class="title"
              >考试延长{{ extendTime }} 分钟</span
            >
            <span class="detail">在此时间内可继续做答!!</span>
          </div>
        </div>
      </div>
    </GlobalDialog>
    <el-badge :is-dot="unReadCounts" class="badge" v-if="$route.name!=='Question'&& $route.name !== 'Complete'">
        <div class="service" @click="openCustomer">
          <i class="iconfont iconkefu"></i>
        </div>
      </el-badge>
      <div class="service-box" v-show="showCustomer">
        <customer
          @messageList="handleMessageList"
          ref="customer"
          :isOpen="showCustomer"
          @closeCustomer="showCustomer = false"
        />
      </div>
    <new-message ref="broadcast" :content="content"></new-message>
    <Message />
    <simple-header :companyInfo="companyInfo" v-if="$route.name !== 'Complete' && $route.name !== 'DeviceDetection'"/>
    <div class="title" v-if="$route.name!=='Question'&&$route.name !== 'Complete' && $route.name !== 'DeviceDetection'">
      {{
        companyName +
        $store.state.examInfo.userExamName +
        $store.state.jobInfo.name
      }}
          <span style="font-weight: normal;font-size: 14px;color: #888">{{
            $store.state.examInfo.scenario
          }}</span>
        </div>
        <slot />
        <simple-footer :shortName="companyInfo.companyShortName" v-if="$route.name!=='Question' && $route.name!=='Complete'"/>
  </div>
  <div class="loading" v-else v-loading="init" />
</template>

<script>
import Message from '@/components/Message'
import NewMessage from '@/components/NewMessage'
import GlobalDialog from '@/components/GlobalDialog'
import { bus } from '@/utils/bus'
import { getItem, setItem } from '@/utils/storage'
import Api from '@/api/api.js'
import whiteList from '@/common/whiteList'
import sound from '@/assets/audio/监考官通话提示.mp3'
import SimpleHeader from '@/components/SimpleHeader'
import SimpleFooter from '@/components/SimpleFooter'
import { mapMutations, mapActions } from 'vuex'
import Customer from '@/components/Customer'
import { Loading } from 'element-ui'

export default {
  data() {
    return {
      // 消息相关
      content: '',
      perMsg: '',
      showOneToOneDialog: false,
      showYellowCardDialog: false,
      showRedCardDialog: false,
      showPhonePositionDialog: false,
      showPropaganda: false,
      showExtendDialog: false,
      showCustomer: false,
      unReadCounts: false,
      // 初始化
      init: false,
      companyName: '',
      companyInfo: {},
      // 延长考试时间
      extendTime: 0
    }
  },
  components: {
    Message,
    NewMessage,
    GlobalDialog,
    SimpleHeader,
    SimpleFooter,
    Customer
  },
  async mounted() {
    Loading.service({ fullscreen: true })
    setTimeout(() => {
      this.getBroadcast()
    }, 3000)
    this.getBroadcastMqtt()
    await this.initData()
    this.getCompanyName()
  },
  methods: {
    ...mapActions([`updateExamInfo`, `updateUserInfo`, `updateJobInfo`]),
    ...mapMutations([`setExamInfo`, `setUserInfo`, `setJobInfo`]),
    /**
     * 初始化
     */
    async initData() {
      // await this.examInfo()
      await this.jobInfo()
      await this.info()
      Loading.service({ fullscreen: true }).close()
      this.init = true
    },
    /**
     * info相关
     */
    async examInfo() {
      const res = await Api.examInfo()
      if (res.code === 0) {
        const resData = res.data
        this.setExamInfo(resData)
        this.updateExamInfo(resData)
      }
    },
    // 获取Job信息
    async jobInfo() {
      const res = await Api.jobInfo()
      if (res.code === 0) {
        const resData = res.data
        this.setJobInfo(resData)
        this.updateJobInfo(resData)
      }
    },
    // 获取用户信息
    async info() {
      const res = await Api.memberInfo()
      if (res.code === 0) {
        const resData = res.data
        this.setUserInfo(resData)
        this.updateUserInfo(resData)
      }
    },
    /**
     * 消息相关
     */
    getBroadcastMqtt() {
      bus.$on('message', (message) => {
        console.log('message', message)
        setItem('msgUuid', message._id)
        switch (message.type) {
          case 3:
            this.broadcast(message.content)
            break
          case 4:
            this.oneToOne(message.content)
            break
          case 5:
            this.propaganda(message.content)
            break
          case 6:
            this.yellowCard(message.content)
            break
          case 7:
            this.redCard(message.content)
            break
          case 8:
            this.phonePosition(message.content)
            break
          case 9:
            this.finishForce()
            break
          case 50:
            this.extendExam(message.content)
            break
        }
      })
    },
    async getBroadcast() {
      const messageUuid = getItem('msgUuid') || ''
      if (whiteList.includes(this.$route.name)) return
      const res = await Api.getBroadcast()
      const resData = res.data
      if (res.code === 0) {
        if (!resData.length) return
        const len = resData.length - 1
        if (resData[len].messageUuid === messageUuid) return
        const lastMsg = resData[len]
        setItem('msgUuid', resData[len].messageUuid)
        switch (lastMsg.type) {
          case 3:
            this.broadcast(resData[len].content)
            break
          case 4:
            this.oneToOne(resData[len].content)
            break
          case 5:
            this.propaganda(resData[len].content)
            break
          case 6:
            this.yellowCard(resData[len].content)
            break
          case 7:
            this.redCard(resData[len].content)
            break
          case 8:
            this.phonePosition(resData[len].content)
            break
          case 9:
            this.finishForce()
            break
          case 50:
            this.extendExam(resData[len].content)
            break
        }
      }
    },
    // 所有人喊话
    broadcast(content) {
      this.$refs.broadcast.open()
      this.content = content
      this.showOneToOneDialog = true
      this.perMsg = this.changeLine(content)
    },
    // 一对一喊话
    oneToOne(content) {
      this.showOneToOneDialog = true
      this.perMsg = this.changeLine(content)
    },
    // 黄牌警告
    yellowCard(content) {
      const { commit } = this.$store
      this.showYellowCardDialog = true
      this.perMsg = this.changeLine(content)
      commit('setShowYellowCard', true)
    },
    // 红牌警告
    redCard(content) {
      const { commit } = this.$store
      this.showRedCardDialog = true
      this.perMsg = this.changeLine(content)
      commit('setShowRedCard', true)
    },
    // 二机位提示
    phonePosition(content) {
      this.showPhonePositionDialog = true
      this.perMsg = this.changeLine(content)
    },
    // 强制结束考试
    async finishForce() {
      const res = await Api.examFinishForce()
      if (res.code === 0) {
        this.$router.push('/complete?isForce=true')
      }
    },
    // 延长考试时间
    extendExam(content) {
      this.showExtendDialog = true
      this.extendTime = content
      // 发送给 header 组件 通过 bus
      bus.$emit('extendTime', content)
    },
    // 喊话提醒
    propaganda(content) {
      this.showPropaganda = true
      const audio = new Audio(sound)
      audio.play()
      this.perMsg = this.changeLine(content)
      setTimeout(() => {
        this.showPropaganda = false
      }, 5000)
    },
    // \n 变换行方法
    changeLine(str) {
      return str.replace(/\n/g, '<br />')
    },
    async messageRead() {
      await Api.messageRead({})
    },
    async handleMessageList() {
      if (this.showCustomer) {
        this.unReadCounts = false
        await this.messageRead()
      } else {
        this.unReadCounts = true
      }
    },
    async openCustomer() {
      this.showCustomer = true
      this.unReadCounts = false
      await this.messageRead()
      setTimeout(() => this.$refs.customer.scrollToBottom(), 100)
    },
    async getCompanyName() {
      const examShortId = getItem('shortId')
      if (!examShortId || examShortId === 'undefined') return
      const res = await Api.getNameAndLogo({ examShortId })
      if (res.code === 0) {
        this.companyInfo = res.data
        this.companyName = res.data.companyShortName
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-container {
  .img-title {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
    display: block;
    text-align: center;
  }
  .content {
    width: 460px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .card {
      width: 100px;
      margin-right: 10px;
    }
    .iconfont {
      font-size: 150px;
      color: #cb2a1d;
      flex: 0.5;
    }
    .icon {
      position: relative;
    }
    .yellow-card_icon {
      flex: 1;
      font-size: 150px;
    }
    .red-card_icon {
      flex: 1;
      font-size: 150px;
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      flex: 1;
      .title {
        width: 100%;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
      }
    }
  }
  .content2 {
    display: flex;
    justify-content: center;
    align-items: center;
    .iconfont {
      margin-right: 10px;
      font-size: 50px;
      color: #cb2a1d;
      flex: 0.5;
    }
  }
}
.main-layout {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.title {
  width: 1230px;
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
  .suffix {
    font-size: 14px;
    font-weight: normal;
    color: #666;
  }
}
.service {
    width: 60px;
    height: 50px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #666;
    background-color: #fff;
    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      .iconfont {
        color: #cb2a1d;
      }
    }
    .iconfont {
      font-size: 24px;
    }
  }
.service-box {
    position: absolute;
    bottom: 80px;
    right: 0px;
    z-index: 2;
}
.badge {
    position: fixed;
    right: 0;
    top: calc(50% - 25px);
    z-index: 2;
  }
  :v-deep .badge .el-badge__content.is-fixed {
    right: 64px;
  }
  ::v-deep .badge .el-badge__content.is-dot {
    width: 13px;
    height: 13px;
    left: -19px;
  }
  .loading {
    width: 100vw;
    height: 100vh;
  }
</style>
