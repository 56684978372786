/*
 * @Author: Libra
 * @Date: 2021-11-08 10:23:02
 * @LastEditTime: 2022-04-07 18:02:00
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-exam-ui/src/icons/index.js
 */
import Vue from 'vue'
import SvgIcon from '@/components/iconSvg' // svg组件

// register globally
Vue.component('svg-icon', SvgIcon)

const requireAll = requireContext => requireContext.keys().map(requireContext)
const req = require.context('./svg', false, /\.svg$/)
requireAll(req)
