/*
 * @Author: Libra
 * @Date: 2022-09-27 17:44:48
 * @LastEditTime: 2022-09-27 17:44:48
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-exam-ui/src/utils/bus.js
 */
import Vue from 'vue'
export const bus = new Vue()
