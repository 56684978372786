/*
 * @Author: Libra
 * @Date: 2021-08-06 14:38:50
 * @LastEditTime: 2022-06-27 11:50:47
 * @LastEditors: Libra
 * @Description: FaceId api
 * @FilePath: /stone-exam-ui/src/api/faceId/index.js
 */
import request from '@/utils/request'
import config from '../config'

/**
 * 判断是不是同一个人
 */
function difference(data) {
  return request({
    url: `${config.EXAM}/face-id/tencent/difference`,
    method: 'post',
    data: data
  })
}
// 人脸对比时间间隔
function differenceTime() {
  return request({
    url: `${config.EXAM}/face-id/difference/time`,
    method: 'get'
  })
}

/**
 * 获取人脸对比结果
 * @returns result
 */
function getFaceIdResult() {
  return request({
    url: `${config.EXAM}/face-id/result`,
    method: 'post'
  })
}
/**
 * face-id token获取
 * @returns token
 */
function getFaceIdToken() {
  return request({
    url: `${config.EXAM}/face-id/token/kyc`,
    method: 'get'
  })
}
export default {
  getFaceIdToken,
  difference,
  getFaceIdResult,
  differenceTime
}
