/*
 * @Author: Libra
 * @Date: 2022-11-16 10:40:46
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-exam-ui/src/directive/click.js
 */
import Vue from 'vue'

const preventReClick = Vue.directive('preventReClick', {
  inserted: function(el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, binding.value || 2000)
      }
    })
  }
})

export { preventReClick }
