/*
 * @Author: Libra
 * @Date: 2021-04-27 10:59:54
 * @LastEditTime: 2023-10-30 18:03:07
 * @LastEditors: Libra
 * @Description: vuex
 * @FilePath: /stone-exam-ui/src/store/index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'
import {
  getToken as getTokenFromCookie,
  setToken as setTokenToCookie,
  removeToken as removeTokenFromCookie
} from '@/utils/auth'
import { getItem, setItem } from '@/utils/storage'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    token: null,
    userInfo: JSON.parse(getItem(`userInfo`) || '{}'), // 用户信息
    examInfo: JSON.parse(getItem(`examInfo`) || '{}'), // 考试信息
    jobInfo: JSON.parse(getItem(`jobInfo`) || '{}'), // 考试信息
    showPc: false,
    showCamera: false,
    showMobile: false,
    pcDetect: false,
    cameraDetect: false,
    mobileDetect: false,
    partTime: null,
    showYellowCard: JSON.parse(getItem(`showYellowCard`) || false),
    showRedCard: JSON.parse(getItem(`showRedCard`) || false),
    isPractice: null,
    companyUuid: null
  },
  getters: {
    token(state) {
      if (!state.token) {
        const token = getTokenFromCookie()
        if (!token) {
          return null
        }
        store.commit('setToken', token)
      }
      return `Bearer ${state.token}`
    }
  },
  mutations: {
    setToken(state, token) {
      setTokenToCookie(token)
      state.token = token
    },
    resetToken(state) {
      removeTokenFromCookie()
      state.token = null
    },
    // 保存用户登录信息
    setUserInfo(state, userInfo) {
      setItem(`userInfo`, JSON.stringify(userInfo))
      state.userInfo = userInfo
    },
    // 保存用户考试信息
    setExamInfo(state, examInfo) {
      setItem(`examInfo`, JSON.stringify(examInfo))
      state.examInfo = examInfo
    },
    setJobInfo(state, jobInfo) {
      setItem(`jobInfo`, JSON.stringify(jobInfo))
      state.jobInfo = jobInfo
    },
    setShowPc(state, showPc) {
      state.showPc = showPc
    },
    setShowCamera(state, showCamera) {
      state.showCamera = showCamera
    },
    setShowMobile(state, showMobile) {
      state.showMobile = showMobile
    },
    setCameraDetect(state, cameraDetect) {
      state.cameraDetect = cameraDetect
    },
    setPcDetect(state, pcDetect) {
      state.pcDetect = pcDetect
    },
    setMobileDetect(state, mobileDetect) {
      state.mobileDetect = mobileDetect
    },
    setPartTime(state, partTime) {
      state.partTime = partTime
    },
    setShowYellowCard(state, showYellowCard) {
      setItem(`showYellowCard`, JSON.stringify(true))
      state.showYellowCard = showYellowCard
    },
    setShowRedCard(state, showRedCard) {
      setItem(`showRedCard`, JSON.stringify(true))
      state.showRedCard = showRedCard
    },
    setPractice(state, isPractice) {
      state.isPractice = isPractice
    },
    setCompanyUuid(state, companyUuid) {
      state.companyUuid = companyUuid
    }
  },
  actions: {
    updateUserInfo(context, value) {
      context.commit('setUserInfo', value)
    },
    updateExamInfo(context, value) {
      context.commit('setExamInfo', value)
    },
    updateJobInfo(context, value) {
      context.commit('setJobInfo', value)
    },
    updateHasRequest(context, value) {
      context.commit('setHasRequest', value)
    }
  },
  modules: {}
})

export default store
