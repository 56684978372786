/*
 * @Author: Libra
 * @Date: 2021-04-28 11:26:59
 * @LastEditTime: 2024-05-22 16:04:29
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-exam-ui/src/router/index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { removeToken } from '@/utils/auth'
import browserInfo from '@/utils/browserInfo'
import whiteList from '@/common/whiteList'

Vue.use(VueRouter)
const checkBrowser = (to, next) => {
  if (
    whiteList.includes(to.name) &&
    to.name !== 'Login' &&
    to.name !== 'PublicLogin'
  ) {
    return
  }
  const info = browserInfo()
  const version = info.shell ? info.shellVs : Number(info.supporterVs)
  const type = info.shell ? Number(info.shell) : info.supporter
  if (type !== 'chrome') {
    next('/broswerCompatibility')
  } else if (version <= 80) {
    next('/broswerCompatibility')
  }
}
const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    meta: { depth: 1 },
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/common/Login.vue')
  },
  {
    path: '/deviceDetection',
    name: 'DeviceDetection',
    component: () =>
      import(
        /* webpackChunkName: "deviceDetection" */ '../views/common/DeviceDetection.vue'
      )
  },
  {
    path: '/basic',
    name: 'ConfirmBasic',
    meta: { depth: 2 },
    component: () =>
      import(
        /* webpackChunkName: "confirmBasic" */ '../views/confirm/basic/index.vue'
      )
  },
  {
    path: '/camera',
    name: 'ConfirmCamera',
    meta: { depth: 3 },
    component: () =>
      import(
        /* webpackChunkName: "confirmCamera" */ '../views/confirm/camera/index.vue'
      )
  },
  {
    path: '/face',
    name: 'FaceRecognition',
    meta: { depth: 3 },
    component: () =>
      import(
        /* webpackChunkName: "faceRecognition" */ '../views/confirm/face/index.vue'
      )
  },
  {
    path: '/notice',
    name: 'ConfirmNotice',
    meta: { depth: 5 },
    component: () =>
      import(
        /* webpackChunkName: "confirmNotice" */ '../views/confirm/notice/index.vue'
      )
  },
  {
    path: '/exam',
    name: 'ConfirmExam',
    meta: { depth: 6 },
    component: () =>
      import(/* webpackChunkName: "confirmExam" */ '../views/confirm/exam/index.vue')
  },
  {
    path: '/question',
    name: 'Question',
    meta: { depth: 7 },
    component: () =>
      import(/* webpackChunkName: "question" */ '../views/question/index.vue')
  },
  {
    path: '/mobile',
    name: 'PhoneMonitor',
    meta: { depth: 4 },
    component: () =>
      import(
        /* webpackChunkName: "phoneMonitor" */ '../views/confirm/mobile/index.vue'
      )
  },
  {
    path: '/complete',
    name: 'Complete',
    meta: { depth: 8 },
    component: () =>
      import(/* webpackChunkName: "complete" */ '../views/common/Complete.vue')
  },
  {
    path: '/device',
    name: 'Device',
    meta: { depth: 8 },
    component: () =>
      import(/* webpackChunkName: "complete" */ '../views/confirm/device/index.vue')
  },
  {
    path: '/broswerCompatibility',
    name: 'BroswerCompatibility',
    component: () =>
      import(
        /* webpackChunkName: "broswerCompatibility" */ '../views/common/BroswerCompatibility.vue'
      )
  },
  {
    path: '/faceReturn',
    name: 'FaceReturn',
    component: () =>
      import(/* webpackChunkName: "faceReturn" */ '../views/common/faceReturn.vue')
  },
  {
    path: '/faceReturnErr',
    name: 'FaceReturnErr',
    component: () =>
      import(
        /* webpackChunkName: "faceReturnErr" */ '../views/common/faceReturnErr.vue'
      )
  },
  {
    path: '/QA',
    name: 'QA',
    component: () => import(/* webpackChunkName: "QA" */ '../views/common/QA.vue')
  },
  {
    path: '/QAClient',
    name: 'QAClient',
    component: () =>
      import(/* webpackChunkName: "QA" */ '../views/common/QAClient.vue')
  },
  {
    path: '/QAClient2',
    name: 'QAClient2',
    component: () =>
      import(/* webpackChunkName: "QA" */ '../views/common/QAClient2.vue')
  },
  {
    path: '/invite',
    name: 'ExamInvite',
    component: () =>
      import(/* webpackChunkName: "invite" */ '../views/common/ExamInvite.vue')
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: () =>
      import(/* webpackChunkName: "confirm" */ '../views/invite/index.vue'),
    children: [
      {
        path: 'refuse',
        name: 'refuse',
        component: () =>
          import(/* webpackChunkName: "refuse" */ '../views/invite/Refuse.vue')
      },
      {
        path: 'consider',
        name: 'consider',
        component: () =>
          import(
            /* webpackChunkName: "consider" */ '../views/invite/Consider.vue'
          )
      },
      {
        path: 'success',
        name: 'success',
        component: () =>
          import(
            /* webpackChunkName: "success" */ '../views/invite/Success.vue'
          )
      },
      {
        path: '/publiclogin',
        name: 'PublicLogin',
        component: () =>
          import(
            /* webpackChunkName: "publicLogin" */ '../views/common/PublicLogin.vue'
          )
      }
    ]
  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: () =>
      import(/* webpackChunkName: "confirm" */ '../views/mobile/index.vue'),
    children: [
      {
        path: 'login',
        name: 'MobileLogin',
        component: () =>
          import(/* webpackChunkName: "refuse" */ '../views/mobile/login.vue')
      }
    ]
  },
  {
    path: '/telecom',
    name: 'Telecom',
    component: () =>
      import ('../views/custom/telecom.vue')
  },
  {
    path: '/test',
    name: 'Test',
    component: () =>
      import ('../views/test/index.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
router.beforeEach(async(to, _, next) => {
  // NProgress.start()
  checkBrowser(to, next)
  if (store.getters.token) {
    if (to.path === '/login' || to.path === '/publiclogin') {
      removeToken()
      next()
    } else {
      next()
    }
  } else {
    if (whiteList.indexOf(to.name) !== -1) {
      next()
    } else {
      next(`/login?redirect=${to.path}`)
      // NProgress.done()
    }
  }
})

export default router
