/*
 * @Author: Libra
 * @Date: 2021-05-20 14:55:05
 * @LastEditTime: 2023-02-14 14:50:22
 * @LastEditors: Libra
 * @Description:通用工具函数
 * @FilePath: /stone-exam-ui/src/utils/common.js
 */
function equar(a, b) {
  if (a === null && b === null) {
    return true
  }
  if (!Array.isArray(a) || !Array.isArray(b)) {
    return false
  }
  // 判断数组的长度
  if (a.length !== b.length) {
    return false
  } else {
    // 循环遍历数组的值进行比较
    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return false
      }
    }
    return true
  }
}
function equar2(a, b) {
  if (a === null && b === null) {
    return true
  }
  if (!Array.isArray(a) || !Array.isArray(b)) {
    return false
  }
  return JSON.stringify(a) === JSON.stringify(b)
}
function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(',')
  var res = arr[0].match(/:(.*?);/)
  if (!res) return
  const mime = res[1]
  var bstr = atob(arr[1])
  var n = bstr.length
  var u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}
// 图片压缩
function compressImg(file, canvas, path) {
  var files
  var read = new FileReader()
  read.readAsDataURL(file)
  return new Promise(function(resolve) {
    read.onload = function(e) {
      var img = new Image()
      img.src = e.target.result
      img.onload = function() {
        // 默认按比例压缩
        var w = this.width
        var h = this.height
        var ctx = canvas.getContext('2d')
        var base64
        // 创建属性节点
        canvas.setAttribute('width', w)
        canvas.setAttribute('height', h)
        ctx.drawImage(this, 0, 0, w, h)
        // 改为jpeg格式，这个方法只支持jpeg和webp格式的图片压缩
        base64 = canvas.toDataURL('image/jpg', 0.2)
        // 回调函数返回file的值（将base64编码转成file）
        files = dataURLtoFile(base64, path) // 如果后台接收类型为base64的话这一步可以省略
        resolve(files)
      }
    }
  })
}

function clearAllTime() {
  let id = setTimeout(() => {}, 0)
  while (id > 0) {
    clearTimeout(id)
    id--
  }
  let id2 = setInterval(() => {}, 0)
  while (id2 > 0) {
    clearInterval(id2)
    id2--
  }
}
// 两个数之间的随机数
// 获取两个数之间的随机一个数
function getRandom(min, max) {
  return Math.floor(Math.random() * (max - min) + min)
}

// 秒转为时分秒 00:00:00 格式
function formatSeconds(value) {
  let theTime = parseInt(value) // 秒
  let theTime1 = 0 // 分
  let theTime2 = 0 // 小时
  if (theTime > 60) {
    theTime1 = parseInt(theTime / 60)
    theTime = parseInt(theTime % 60)
    if (theTime1 > 60) {
      theTime2 = parseInt(theTime1 / 60)
      theTime1 = parseInt(theTime1 % 60)
    }
  }
  let result = ''
  if (theTime2 > 0) {
    result = `${theTime2 < 10 ? '0' + theTime2 : theTime2}:`
  }
  result += `${theTime1 < 10 ? '0' + theTime1 : theTime1}:`
  result += `${theTime < 10 ? '0' + theTime : theTime}`
  return result
}
export { equar, equar2, dataURLtoFile, compressImg, clearAllTime, getRandom, formatSeconds }
