/*
 * @Author: Libra
 * @Date: 2021-07-15 11:56:47
 * @LastEditTime: 2024-04-08 11:22:24
 * @LastEditors: Libra
 * @Description:白名单
 * @FilePath: /stone-exam-ui/src/common/whiteList.js
 */
export default [
  'Login',
  'MobileLogin',
  'PublicLogin',
  'DeviceDetection',
  'BroswerCompatibility',
  'ExamInvite',
  'Complete',
  'QA',
  'QAClient',
  'QAClient2',
  'FaceReturnErr',
  'FaceReturn',
  'refuse',
  'success',
  'consider',
  'MobileVideo',
  'Telecom',
  'Test'
]
