/*
 * @Author: Libra
 * @Date: 2021-06-16 10:02:26
 * @LastEditTime: 2023-05-10 14:25:15
 * @LastEditors: Libra
 * @Description: 常见问题问答
 * @FilePath: /stone-exam-ui/src/common/qa.js
 */
export default [
  {
    title: '系统登录问题',
    question: [
      {
        Q: '考试系统登录不了，怎么办？',
        A:
          '1、请将邮件或短信中的登录路径/考试入口链接复制粘贴至谷歌浏览器，建议不要直接点进链接；2、请仔细核对登录账号、密码等信息，复制或键盘输入密码时，不要有错误；3、如果参加过多场在国考云举办的考试，登录时浏览器会自动保存上一次考试的账户信息，请务必用最新的考试链接和密码登录系统，否则会提示考试已结束。如果使用最新登录链接和密码仍显示考试已结束，请按ctrl＋shift＋del键清除浏览器缓存（苹果电脑command＋shift＋del）时间范围选择时间不限或全部，点击清除数据重新登录即可。'
      },
      {
        Q: '没有收到笔试通知邮件，或用户名和密码遗失，怎么办？',
        A:
          '1、先从邮件中查询相关的考试邀请信，如果收件箱里没有，建议在垃圾箱或广告邮件里查询；2、在收到的邀请短信中会有笔试账号网址，打开即可看到账号等所有相关信息。如果都删除了，请联系考试的组织方获取。'
      },
      {
        Q: '考试中途网络中断，或电脑故障，如何处理？',
        A:
          '答题过程中因网络故障、电脑故障等造成中断，请重新登录系统，并在几分钟之内再次按照相同的步骤进入考试，从中断处继续答题即可，不需要重复作答之前已经答过的试题。如果电脑还有故障，建议用手机4G热点上网，或更换另外一台电脑继续作答。'
      }
    ]
  },
  {
    title: '人脸身份验证问题',
    question: [
      {
        Q: '如何进行人脸身份验证？',
        A:
          '如果系统要求考生进行身份验证，请您按照系统指示，在信息采集页填好身份信息后，用手机微信扫码进行身份验证。请按手机页面提示操作，将头部放置在头像采集框中，在光亮的环境下完成身份验证。'
      },
      {
        Q: '人脸身份验证三次都失败，我该怎么办？',
        A:
          '每位考生有三次人脸身份验证的机会，若您三次机会都失败了，请您选择拍照，面对电脑屏幕手动点击拍照采集人像即可，考试结束后我们会进行人工比对。'
      }
    ]
  },
  {
    title: '摄像头问题',
    question: [
      {
        Q: '考试要求使用摄像头，但我电脑没有摄像头, 摄像头故障怎么办？',
        A:
          '1、电脑摄像头正常开启对考试非常重要，为了保证考试的公平性，以及验证考生身份的真实性，系统会全程视频监控考生作答的情况，因此请务必保证摄像头可以正常使用；2、建议提前登录考试系统，检测电脑摄像头是否正常，如有故障，请重启电脑，或更换摄像头、电脑来解决。'
      },
      {
        Q: '摄像头无故障，但就是无法看到图像',
        A:
          '通常有四种原因：1. 浏览器将摄像头禁用，请查看地址栏右侧是否有打叉的摄像头图标。或者点击谷歌浏览器右上角三个竖点—设置—隐私设置和安全性—网站设置，授予权限；2. 有些品牌电脑的摄像头会有个开关按钮，例如联想的电脑，请务必把开关键打开；3. 另一个浏览器或应用程序占用了摄像头，请重启电脑即可解决；4. 软件冲突，请关闭360魔法摄像头之类的工具软件，重启电脑即可解决'
      },
      {
        Q: '摄像头显示后置画面，如何切换到前置画面？',
        A:
          '当您的电脑摄像头显示为后置画面时，可进行如下尝试：1、请您按esc键，点击谷歌浏览器右上角三个点--进入设置--安全和隐私设置--网站设置--摄像头--页面最上方下拉选择另外一个摄像头，然后刷新页面即可看到前置画面。2、如果上述步骤操作后仍不行，可尝试如下操作，首先打开电脑的设置——设备——蓝牙和其他设备——设备和打印机，弹出窗口，找到前置摄像头，右键选择属性，将该摄像头设置为默认摄像头，如果到设备和打印机这步没自动弹出弹窗，请在空白处右键，选择设备管理器，进入后找到摄像头。如果更换后仍无法开启，请更换新的电脑设备。'
      },
      {
        Q: '摄像头对准哪里算是合规？',
        A:
          '请调整摄像头角度，将头像放置在画面中间位置，能清晰看清脸部，确保在整个答题过程中脸部正对摄像头。'
      }
    ]
  },
  {
    title: '系统使用问题',
    question: [
      {
        Q: '我没有跳出，但系统为何提示我离开考试页面？',
        A:
          '切换到其他页签或浏览器、广告弹窗、通讯软件消息、后台程序更新等都会引起此类问题，请在3秒钟内立刻关闭弹窗，回到考试页面，系统不会提示跳出的。共享桌面的页签也不要点击，点击后系统也会提示跳出。鼠标长时间不操作，不算跳出。'
      },
      {
        Q: '无法共享屏幕？',
        A:
          '如果您使用的是苹果电脑请关闭谷歌浏览器回到电脑主屏幕，点击左上角苹果图标，进入系统偏好设置，进入隐私与安全性，找到屏幕录制，勾选chrome浏览器，重新登录后再按ctrl+R刷新页面即可！如果您使用的是Windows系统电脑，将鼠标光标移动至屏幕监控图标异常处，根据提示操作即可！'
      },
      {
        Q: '问答题有个扫码传图的功能，我用手机扫描二维码后，无法上传图片',
        A:
          '手机扫码后如果拍照上传不了图片，则建议先用手机本机的相机拍照好，然后扫码从相册添加图片即可。如果还不行，建议换个手机试试。'
      },
      {
        Q: '选择题全部作答后，还能修改吗？',
        A:
          '选择题在提交该部分小试卷前，系统会有弹窗提示，告知是否可以修改已作答内容，请参见系统提示操作。'
      },
      {
        Q:
          '考试结束时，没有及时点击“我要交卷”按钮，作答的试题内容都保存上了吗？',
        A:
          '所有试题只要点击了“下一题”按钮，都会自动保存当前题目作答信息的；考试到结束时，系统会自动把所有试题最新的作答情况全部保存到系统中，并自动交卷，请放心。'
      },
      {
        Q: '作答录制视频题时，回放录制的视频为什么没声音？',
        A:
          '首先要确认浏览器是否授予了麦克风权限，点击esc缩小屏幕，点击谷歌浏览器右上角竖着的三个点--设置--隐私设置与安全性--网站设置，确认是否开启了麦克风权限。开启后重新录制一段小视频，打开电脑扬声器，确认是否可以听到声音。'
      }
    ]
  },
  {
    title: '网速卡顿问题',
    question: [
      {
        Q: '打开考试系统页面很慢，比较卡顿，无法点击“下一题”',
        A:
          '可以尝试下面两种方式解决：1. 更换上网方式：建议使用4G手机分享Wifi热点，通过电脑的无线设备连接手机的Wifi热点上网，正常的网速都比较快。或者更换到Wifi信号强的地方再次尝试；2. 刷新网页：刷新页面或者退出系统，在登录页刷新网页后，重新登录系统作答。'
      },
      {
        Q: '人在国外，网络环境不理想，操作系统卡顿，怎么办？',
        A:
          '可以尝试下面三种方式解决：1. 更换上网方式：建议用4G手机分享Wifi热点，通过电脑连接手机Wifi上网；或者更换到Wifi信号强的地方再次尝试；2. 关闭国外通道的VPN，可以尝试使用国内通道的VPN；如果在实习公司考试，建议不要使用公司网络；3. 重启电脑，或者更换一台电脑，再尝试一下'
      }
    ]
  },
  {
    title: '试题相关问题',
    question: [
      {
        Q: '考试还没开始，为什么我现在可以作答试题？',
        A:
          '考试正式开始之前，登录到考试系统，开放的试题为考前练习题，不是正式的考题，题型和知识点均与正式考题无关，目的在于让考生们体验答题环境，熟悉系统操作；正式开考时，页面会自动切换到正式考题。'
      },
      {
        Q: '我申请的职位和练习题考点不同，实际考试也这样吗？',
        A:
          '练习试题主要目的是让考生适应答题环境，熟悉系统操作；正式考试会依据您考试的职位，在题型、考点、难度都会有差异。'
      },
      {
        Q: '没有做考前练习题，对我的考试成绩有影响吗？',
        A:
          '未作答练习题不会对考试结果产生任何影响，但我们仍建议各位考生进行考前练习。'
      },
      {
        Q: '填空题的填写规范格式有哪些？',
        A:
          '请仔细阅读试题的要求，填写的内容要规范，很多填空题是系统自动比对答案评分的，因此您填写的内容务必跟答案完全一致，才能得分。'
      },
      {
        Q: '题目中图片加载不出来、只显示文字信息，没有选项怎么办？',
        A: '您当前浏览器没有加载完全页面信息，请按Ctrl＋R键刷新网页即可。'
      },
      {
        Q: '某题的题干、选项是不是有问题？',
        A:
          '如果发现有问题的试题，请在网页对话框中告知我们：试题序号+试题疑问点。如题干、选项确实有误，我们会及时更新试题，并统一发送系统公告告知大家。'
      },
      {
        Q: '作答问答题时，系统提供的画板功能有限，使用不便，怎么办？',
        A:
          '如果不习惯使用系统提供的画图板功能，建议在白纸上画出相应的图，然后使用手机拍照扫描上传图片的功能，把该图上传到系统即可。'
      }
    ]
  },
  // {
  //   title: '编程题相关问题',
  //   question: [
  //     {
  //       Q: '编程题编译环境的相关提问',
  //       A:
  //         '编程题相关问题，请务必仔细参考： https://exam-file.iguokao.com/oj.html'
  //     },
  //     {
  //       Q: '在线编程题支持哪些语言进行编译？',
  //       A:
  //         '在线编程题目前支持Java、C++、C、C#、Python、JavaScript、PHP、SQL、Swift、Go、Obj-C、 Ruby、Kotlin、R、Rust、Scala、Groovy和Bash等18种语言，不同职位可能会有具体的语言要求，请仔细查看。'
  //     },
  //     {
  //       Q: '本地编译通过，但考试系统中提交却不通过',
  //       A:
  //         '考试系统的编译器可能与你本地编译器略有不同，请根据编译错误信息耐心调试。每个编程题都有5-10组测试数据，你的代码在本机可以通过仅仅是通过了样例数据，可能存在边界条件考虑不周导致更多的测试数据没有通过，所以会返回答案错误；编程题有自定义数据的功能，你可以自己创建一例输入&输出数据，运行后查看编译结果，并调试代码；强烈建议考前练习时，多尝试几道编程题，熟悉编译器要求，并仔细阅读考试通知里编程题须知页面的内容。'
  //     },
  //     {
  //       Q: '在线编程题总是报运行错误，是什么情况？',
  //       A:
  //         '运行错误一般都是数组越界非法访问，野指针乱访问，空指针乱访问等情况造成代码奔溃。'
  //     },
  //     {
  //       Q: '点击调试后，一直显示正在编译，我该怎么办？',
  //       A:
  //         '如果集中提交代码的同学较多，编译反馈的结果会慢一些，此时你可以关闭调试小窗，先作答下一题，等提交高峰过去后，再尝试提交之前的代码；考试结束前10分钟，提交代码反馈的时间比较长，建议在此时间前提交代码。'
  //     },
  //     {
  //       Q:
  //         '编程题最新的代码，我只点击了保存或下一题，没有点击“调试”运行，系统会给这道题记分吗？',
  //       A:
  //         '首先，系统会按照您最后一次保存的代码作为最终提交代码，改题的评分也是按照此代码的运行结果进行判分；如果您没有把最终提交的代码，在考试期间进行“调试”运行，或者运行后考试时间截止，系统自动关闭，没有看到反馈结果，我们都会在考试结束后，把您最终代码重新提交到判题系统运行，因此，请放心，最终的代码肯定会被审阅，都会正确记分的。'
  //     },
  //     {
  //       Q:
  //         '编程题记分规则是什么？本地调试通过了，但系统总是提示运行代码不正确，该题还能得分吗？',
  //       A:
  //         '编程题的记分规则是：按照每个测试数据记分，假如该题有5个测试数据，如果您运行通过一个，就会得到相应的分数，不同数据的分值不同，全部答对得满分。因此即便系统提示你运行代码不正确，也是有可能获得部分分数。考试结束后，我们会在后台查看每位考生的每道题通过测试数据的数量，结合代码编写的情况，给予相应的分数。'
  //     },
  //     {
  //       Q:
  //         '显示AC (Accepted)是不是就算是通过？还是说编译通过不一定代表全做对了？',
  //       A: '显示AC即表示通过所有测试数据，改题可以得满分。'
  //     }
  //   ]
  // },
  {
    title: '其他问题',
    question: [
      {
        Q: '关于调整考试时间',
        A:
          '此次考试为全国所有考生统一考试，在同一个考试时间，不能调整，如有特殊情况，可以直接与考试主办方联系。'
      },
      {
        Q: '什么时候进行后续面试',
        A:
          '如果后续有面试安排，考试结束后，考试主办方会根据所有考生笔试成绩，尽快安排后期的面试，具体面试时间，随后会另行通知，请随时留意官方渠道发布的消息。'
      },
      {
        Q: '考试过程中是否可以使用手机？',
        A:
          '考试全程严禁使用手机交流或查询信息，请遵守考场纪律。'
      },
      {
        Q: '收到了通知短信，但没有收到通知邮件，可以参加考试吗？',
        A:
          '邮件和短信的拦截功能会导致某种方式收不到信息，邮件和短信里考试信息打开的网页内容信息是完全一样的，打开任何一个方式查看考试信息即可；另，请务必点击“确认参加”或“放弃参加”按钮。'
      }
    ]
  }
]
