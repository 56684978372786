<!--
 * @Author: Libra
 * @Date: 2021-05-24 17:51:11
 * @LastEditTime: 2022-11-15 15:15:11
 * @LastEditors: Libra
 * @Description:Footer
 * @FilePath: /stone-exam-ui/src/components/SimpleFooter/index.vue
-->
<template>
  <div class="footer">
    主办方：{{ shortName }}
    <span class="line">|</span>
    本网站由<a
      style="color: #717171"
      href="https://www.iguokao.com"
      target="view_window"
      >国考云</a
    >考试系统提供技术支持
  </div>
</template>

<script>
export default {
  name: 'SimpleFooter',
  props: {
    shortName: {
      type: String,
      default: '国考云'
    }
  }
}
</script>

<style scoped lang="scss">
@import './index.scss';
</style>
