var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "header",
      style:
        _vm.$route.name === "Question"
          ? "box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);"
          : "",
    },
    [
      _c(
        "GlobalDialog",
        {
          attrs: {
            dialogVisible: _vm.showSubmitingDialog,
            title: "交卷中",
            width: "500px",
            isShowFooter: false,
            show_close: false,
          },
          on: {
            "dialog-cancel": function ($event) {
              _vm.showSubmitingDialog = false
            },
          },
        },
        [
          _c("div", { staticClass: "camera-snap-container" }, [
            _c("div", { staticClass: "content" }, [
              _c("i", { staticClass: "iconfont icontishi" }),
              _c("div", { staticClass: "right" }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v("正在交卷中...."),
                ]),
                _c("span", { staticClass: "detail" }, [_vm._v("请稍后！")]),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "GlobalDialog",
        {
          attrs: {
            dialogVisible: _vm.showRemainMinutes,
            title: "提示",
            width: "500px",
          },
          on: {
            "dialog-cancel": function ($event) {
              _vm.showRemainMinutes = false
            },
          },
        },
        [
          _c("div", { staticClass: "camera-snap-container" }, [
            _c("div", { staticClass: "content" }, [
              _c("i", { staticClass: "iconfont icontishi" }),
              _c("div", { staticClass: "right" }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v("作答时间剩余"),
                  _c(
                    "span",
                    { staticStyle: { color: "#f35a5a", "font-size": "30px" } },
                    [_vm._v(" 5 ")]
                  ),
                  _vm._v("分钟"),
                ]),
                _c("span", { staticClass: "detail" }, [
                  _vm._v("请掌控答题进度，及时交卷！"),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "GlobalDialog",
        {
          attrs: {
            dialogVisible: _vm.showScreenShare,
            title: "录屏共享桌面提示",
            width: "600px",
            show_close: false,
            btn_title: "开始共享屏幕",
          },
          on: { "dialog-cancel": _vm.screenShare },
        },
        [
          _c("div", { staticClass: "camera-snap-container" }, [
            _c("div", { staticClass: "content screen-share" }, [
              _c("span", { staticClass: "title" }, [
                _vm._v(
                  "* 本场考试要求录制您的电脑，请根据浏览器提示共享您的电脑, 关闭录屏将会影响您的成绩。"
                ),
              ]),
              _c("img", {
                staticStyle: {
                  width: "500px",
                  height: "auto",
                  top: "0",
                  transform: "translate(0)",
                },
                attrs: {
                  src: require("@/assets/images/screenShare.jpeg"),
                  alt: "",
                },
              }),
            ]),
          ]),
        ]
      ),
      _c(
        "GlobalDialog",
        {
          attrs: {
            dialogVisible: _vm.showPhoneDetectError,
            width: "500px",
            show_close: false,
            title: "提示",
          },
          on: { "dialog-cancel": _vm.closeQRErr },
        },
        [
          _c("div", { staticClass: "qr-tip" }, [
            _c(
              "div",
              { staticClass: "qr-container" },
              [
                _c("vue-qr", {
                  staticStyle: {
                    width: "150px",
                    height: "150px",
                    transform: "translateY(0)",
                    "max-width": "300px",
                  },
                  attrs: { size: 800, text: _vm.pagePath },
                }),
                _vm.isFailure
                  ? _c(
                      "div",
                      { staticClass: "refresh-qr" },
                      [
                        _c("span", { staticClass: "failure" }, [
                          _vm._v("二维码已失效"),
                        ]),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: { click: _vm.refresh },
                          },
                          [_vm._v("请点击刷新")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("div", { staticClass: "right" }, [
              _c("span", { staticClass: "title red" }, [
                _c("div", [_vm._v("手机端视频监控异常断开")]),
              ]),
              _c("span", { staticClass: "title" }, [
                _vm._v("请用微信扫码重连，15秒后状态恢复正常"),
              ]),
              _c("span", { staticClass: "content" }, [
                _vm._v(
                  "未开启视频监控，会影响考试结果。请关闭手机自动息屏，保持手机常亮！ "
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "header-container",
          style: _vm.isProgram
            ? "width: 100%;padding: 0 20px"
            : "width: 1230px",
        },
        [
          _c("div", { staticClass: "img-contaienr" }, [
            _vm.logo
              ? _c("img", {
                  staticClass: "logo",
                  attrs: { src: _vm.logo, alt: "企业LOGO" },
                })
              : _vm.logo === null
              ? _c("img", {
                  staticClass: "logo",
                  attrs: {
                    src: require("../../assets/images/logo_igp.png"),
                    alt: "企业LOGO",
                  },
                })
              : _vm.logo === undefined
              ? _c("span")
              : _vm._e(),
          ]),
          _vm.hasRight
            ? _c("div", { staticClass: "right" }, [
                _vm.$store.state.showPc
                  ? _c(
                      "div",
                      {
                        staticClass: "detect-container pc-detect",
                        style: {
                          color: _vm.$store.state.pcDetect ? "#666" : "#cb2a1d",
                        },
                        on: {
                          click: function ($event) {
                            _vm.showScreenShare = true
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "iconfont icondiannaoban" }),
                        _c("span", { staticClass: "detecting" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$store.state.pcDetect
                                ? "正在监控"
                                : "监控异常"
                            )
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm.$store.state.showMobile && !_vm.showMobileIcon
                  ? _c(
                      "div",
                      {
                        staticClass: "detect-container mobile-detect",
                        style: {
                          color: _vm.$store.state.mobileDetect
                            ? "#666"
                            : "#cb2a1d",
                        },
                        on: {
                          mouseenter: _vm.displayQr,
                          mouseleave: function ($event) {
                            _vm.showQr = false
                          },
                        },
                      },
                      [
                        _vm.showQr
                          ? _c(
                              "div",
                              { staticClass: "qr" },
                              [
                                _c("vue-qr", {
                                  staticStyle: {
                                    width: "200px",
                                    height: "160px",
                                  },
                                  attrs: { size: 800, text: _vm.pagePath },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("i", { staticClass: "iconfont iconshouji01" }),
                        _c("span", { staticClass: "detecting" }, [
                          _vm._v(
                            _vm._s(
                              _vm.initMobile
                                ? "未开启"
                                : _vm.$store.state.mobileDetect
                                ? "正在监控"
                                : "监控异常"
                            )
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm.$store.state.showCamera
                  ? _c(
                      "div",
                      {
                        staticClass: "detect-container",
                        style: {
                          color: _vm.$store.state.cameraDetect
                            ? "#666"
                            : "#cb2a1d",
                        },
                      },
                      [
                        !_vm.$store.state.cameraDetect
                          ? _c("i", {
                              staticClass: "iconfont iconshexiangtou2",
                            })
                          : _c(
                              "svg",
                              {
                                attrs: {
                                  width: "127",
                                  height: "75",
                                  viewBox: "0 0 127 151",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("g", { attrs: { id: "shexiangtou 1" } }, [
                                  _c("path", {
                                    attrs: {
                                      id: "Vector",
                                      d: "M63.0996 126.199C28.3211 126.199 0 97.8781 0 63.0996C0 28.3211 28.3211 0 63.0996 0C97.8781 0 126.2 28.3209 126.2 63.0994C126.2 97.8779 97.8785 126.199 63.1 126.199L63.0996 126.199ZM63.0996 12.7111C35.3379 12.7111 12.7113 35.2869 12.7113 63.0996C12.7113 90.9123 35.2869 113.488 63.0996 113.488C90.9123 113.488 113.488 90.9123 113.488 63.0996C113.488 35.2869 90.8617 12.7113 63.0996 12.7113V12.7111Z",
                                      fill: "#666",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      id: "Rectangle 2",
                                      d: "M56 113C56 113 58.5 113.5 63 113.5C67.5 113.5 70 113 70 113V141H56V113Z",
                                      fill: "#666",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      id: "Vector_2",
                                      d: "M29.7447 138.097H96.4037V150.809H29.7447V138.097Z",
                                      fill: "#666",
                                    },
                                  }),
                                  _c("circle", {
                                    attrs: {
                                      id: "cameras",
                                      cx: "64",
                                      cy: "63",
                                      r: "14",
                                      fill: "#666",
                                    },
                                  }),
                                ]),
                              ]
                            ),
                        _c("span", { staticClass: "detecting" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$store.state.cameraDetect
                                ? "正在监控"
                                : "监控异常"
                            )
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm.isInPart && _vm.isPartTime
                  ? _c("div", { staticClass: "count-down-part-container" }, [
                      _c("span", { staticClass: "count-down-title" }, [
                        _vm._v(_vm._s("小卷倒计时")),
                      ]),
                      _c("span", { staticClass: "count-down" }, [
                        _vm._v(
                          _vm._s(String(_vm.hourPart).padStart(2, "0")) +
                            ":" +
                            _vm._s(String(_vm.minutePart).padStart(2, "0")) +
                            ":" +
                            _vm._s(String(_vm.secondPart).padStart(2, "0"))
                        ),
                      ]),
                    ])
                  : _vm._e(),
                !_vm.isInPart || !_vm.isPartTime
                  ? _c("div", { staticClass: "count-down-container" }, [
                      _c("span", { staticClass: "count-down-title" }, [
                        _vm._v(_vm._s(_vm.flag ? "距考试结束" : "开考倒计时")),
                      ]),
                      _c("div", [
                        _vm.day <= 0
                          ? _c("span", { staticClass: "count-down" }, [
                              _vm._v(
                                _vm._s(String(_vm.hour).padStart(2, "0")) +
                                  ":" +
                                  _vm._s(String(_vm.minute).padStart(2, "0")) +
                                  ":" +
                                  _vm._s(String(_vm.second).padStart(2, "0"))
                              ),
                            ])
                          : _c("span", { staticClass: "count-down" }, [
                              _vm._v(_vm._s(_vm.day) + " "),
                              _c(
                                "span",
                                { staticStyle: { "font-size": "14px" } },
                                [_vm._v("天")]
                              ),
                            ]),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "personal-info-container" }, [
                  _c(
                    "div",
                    {
                      ref: "avatar",
                      staticClass: "icon-container",
                      style: _vm.$store.state.showRedCard
                        ? "border: 2px solid #D95154"
                        : _vm.$store.state.showYellowCard
                        ? "border: 2px solid #FFDE3C"
                        : "border: 1px solid rgb(155, 155, 155)",
                    },
                    [
                      !_vm.$store.state.userInfo.avatar
                        ? _c("i", { staticClass: "iconfont iconyonghu8" })
                        : _c("img", {
                            staticStyle: { width: "60px", height: "60px" },
                            attrs: {
                              src: `${_vm.file_host}${_vm.$store.state.userInfo.avatar}?${_vm.avatarRandom}`,
                              alt: "",
                            },
                          }),
                    ]
                  ),
                  _c("div", { staticClass: "info-right" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.$store.state.userInfo.realName)),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "exit",
                        on: {
                          click: function ($event) {
                            return _vm.logout()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "iconfont icontuichu" }),
                        _c("span", { staticClass: "exit-title" }, [
                          _vm._v("退出"),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }