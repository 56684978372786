/*
 * @Author: Libra
 * @Date: 2021-05-24 17:51:11
 * @LastEditTime: 2022-08-19 10:43:59
 * @LastEditors: Libra
 * @Description: api
 * @FilePath: /stone-exam-ui/src/api/api.js
 */
import candidate from './candidate'
import exam from './exam'
import faceId from './faceId'
import message from './message'
import monitor from './monitor'
import oj from './oj'
import rtc from './rtc'
import mobile from './mobile'

export default {
  ...candidate,
  ...exam,
  ...faceId,
  ...message,
  ...monitor,
  ...oj,
  ...rtc,
  ...mobile
}
