<!--
 * @Author: Libra
 * @Date: 2021-05-17 10:22:54
 * @LastEditTime: 2023-02-14 10:46:42
 * @LastEditors: Libra
 * @Description:全局通用的弹窗组件
 * @FilePath: /stone-exam-ui/src/components/GlobalDialog/index.vue
-->
// 弹窗组件
<template>
  <el-dialog
    :modal-append-to-body="false"
    :title="title"
    :visible.sync="visible"
    :width="width"
    :modal="showModal"
    :before-close="before_close"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="close"
    :custom-class="customClass"
    :show-close="show_close"
  >
    <div class="slot-container">
      <slot />
    </div>
    <span slot="footer" class="dialog-footer" v-if="isShowFooter">
      <el-button
        class="known-button"
        type="danger"
        plain
        @click="cancel"
        :disabled="disabled"
        >{{ btn_title || '我知道了' }}</el-button
      >
      <el-button
        v-if="showSecond"
        class="known-button"
        type="danger"
        plain
        :disabled="disabled2"
        @click="ok"
        >{{ btn_title2 || '我知道了' }}</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'DialogExample',
  props: {
    title: String,
    btn_title: String,
    btn_title2: String,
    disabled: {
      type: Boolean,
      default: false
    },
    disabled2: {
      type: Boolean,
      default: false
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    show_close: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '430px'
    },
    isShowFooter: {
      type: Boolean,
      default: true
    },
    showSecond: {
      type: Boolean,
      default: false
    },
    before_close: {
      type: Function
    },
    customClass: {
      type: String,
      require: false
    },
    showModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false
    }
  },
  watch: {
    visible() {
      if (this.visible === false) {
        this.$emit('close')
      }
    },
    dialogVisible() {
      if (this.visible !== this.dialogVisible) {
        this.visible = this.dialogVisible
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('dialog-cancel')
    },
    ok() {
      this.$emit('dialog-ok')
    },
    close() {
      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
