/*
 * @Author: Libra
 * @Date: 2021-04-27 10:59:54
 * @LastEditTime: 2022-11-15 14:58:13
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-exam-ui/src/common/element.js
 */
import {
  Form,
  FormItem,
  Button,
  Input,
  Steps,
  Step,
  RadioGroup,
  Radio,
  RadioButton,
  Dialog,
  Checkbox,
  CheckboxGroup,
  MessageBox,
  Message,
  Loading,
  Slider,
  Select,
  Option,
  Tabs,
  TabPane,
  Upload,
  Progress,
  Table,
  TableColumn,
  Tooltip,
  DatePicker,
  Badge
} from 'element-ui'

export default {
  install(V) {
    V.use(Form)
    V.use(FormItem)
    V.use(Button)
    V.use(Input)
    V.use(Steps)
    V.use(Step)
    V.use(RadioGroup)
    V.use(Radio)
    V.use(RadioButton)
    V.use(Dialog)
    V.use(CheckboxGroup)
    V.use(Checkbox)
    V.use(Slider)
    V.use(Select)
    V.use(Option)
    V.use(Tabs)
    V.use(TabPane)
    V.use(Upload)
    V.use(Progress)
    V.use(Table)
    V.use(TableColumn)
    V.use(Tooltip)
    V.use(Badge)
    V.use(DatePicker)
    V.use(Loading)
    V.prototype.$msgbox = MessageBox
    V.prototype.$message = Message
    V.prototype.$loading = Loading.service
  }
}
