/*
 * @Author: Libra
 * @Date: 2021-04-27 10:59:54
 * @LastEditTime: 2024-05-21 15:02:15
 * @LastEditors: Libra
 * @Description:request.js
 * @FilePath: /stone-exam-ui/src/utils/request.js
 */
import axios from 'axios'
import store from '@/store'
import router from '../router/index'
import { removeToken } from '@/utils/auth'
import { Message, MessageBox } from 'element-ui'

// create an axios instance
const service = axios.create({
  baseURL: '',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 * 2 // request timeout
})

// function generateReqKey(config) {
//   const { method, url, params, data } = config
//   return [method, url, JSON.stringify(params), JSON.stringify(data)].join('&')
// }
// const pendingRequest = new Map()
// function addPendingRequest(config) {
//   const requestKey = generateReqKey(config)
//   config.cancelToken =
//     config.cancelToken ||
//     new axios.CancelToken(cancel => {
//       if (!pendingRequest.has(requestKey)) {
//         pendingRequest.set(requestKey, cancel)
//       }
//     })
// }
// function removePendingRequest(config) {
//   const requestKey = generateReqKey(config)
//   if (pendingRequest.has(requestKey)) {
//     const cancelToken = pendingRequest.get(requestKey)
//     cancelToken(requestKey)
//     pendingRequest.delete(requestKey)
//   }
// }
// request interceptor
service.interceptors.request.use(
  config => {
    // removePendingRequest(config) // 检查是否存在重复请求，若存在则取消已发的请求
    // addPendingRequest(config) // 把当前请求信息添加到pendingRequest对象中
    const token = store.getters.token
    if (token) {
      config.headers['Authorization'] = token
    }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    if (response.headers['content-type'] && response.headers['content-type'].includes('text/html')) {
      MessageBox.confirm(
        '您的请求带有不合法参数，已被网站管理员设置拦截!',
        '安全狗提示',
        {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
      return response.data
    }
    const res = response.data
    // 运行代码处理中
    if (res.code === 40002) {
      return res
    }
    if (res.code === 1001) {
      return res
    }
    // 字母题
    if (res.code === 30010) {
      return res
    }
    // 登录五分钟不允许交卷
    if (res.code === 30026) {
      return res
    }
    // 您的考试已开始
    if (res.code === 30023) {
      return res
    }

    if (res.code === 30003) {
      return res
    }
    // 当前子卷必须全部作答
    if (res.code === 30055) {
      return res
    }
    const isFinish = response.config.url.endsWith('/exam/finish')
    // 您的考试已结束
    if (res.code === 30024 && !isFinish) {
      MessageBox.confirm(
        `<div>
        <div style="font-size:18px;font-weight:bold;margin-bottom:10px;">密码错误或考试已结束</div>请务必从邮件或短信中复制粘贴最新的密码，不要使用浏览器自动保存的上一次考试的密码。如果密码无误，则该场考试已结束。
        </div>`,
        '登录异常',
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确认',
          callback: action => {
            router.push('/login')
          },
          type: 'warning'
        }
      )
      return res
    }
    if (res.code === 30008) {
      MessageBox.confirm(
        '用户登录异常！请记录当前题作答内容，退出系统并重新登录。',
        '登录异常',
        {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
    }
    // if the custom code is not 20000, it is judged as an error.
    if (res.code && res.code !== 0) {
      Message({
        message: res.message || '出现了错误',
        type: 'error',
        duration: 5 * 1000
      })
      // 考试已经结束
      if (res.code === 30024) {
        // 清除token
        removeToken()
      }
      // 图片验证码失败
      if (res.code === 30121) {
        return res
      }
      // 使用设备错误
      if (res.code === 30030) {
        return res
      }
      // 账号不存在或者密码错误
      if (res.code === 30001) {
        return res
      }

      if (res.code === 10033) {
        return res
      }
      // 考试迟到，不能登录
      if (res.code === 30027) {
        return res
      }
      if (res.code === 1005 || res.code === 1031 || res.code === 1032) {
        // to re-login
        MessageBox.confirm('令牌过期，或者没有登录', '确认登出', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          store.commit('resetToken')
          router.push('/login')
        })
        return res
      }
      return Promise.reject(res.message || '出现了错误')
    } else {
      return res
    }
  },
  error => {
    // removePendingRequest(error.config || {}) // 从pendingRequest对象中移除请求
    // Message({
    //   message: error || '出现了错误',
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    if (axios.isCancel(error)) {
      console.log('已取消的重复请求：' + error.message)
    } else {
      if (String(error).includes('Network Error')) {
        Message({
          message: '网络错误!!',
          type: 'error',
          duration: 5 * 1000
        })
      }
    }
    return Promise.reject(error)
  }
)

export default service
