/*
 * @Author: Libra
 * @Date: 2021-08-06 14:56:37
 * @LastEditTime: 2024-04-10 15:42:22
 * @LastEditors: Libra
 * @Description: 考生 api
 * @FilePath: /stone-exam-ui/src/api/candidate/index.js
 */
import request from '@/utils/request'
import config from '../config'

// 更新头像
function avatarUpdate(data) {
  return request({
    url: `${config.EXAM}/candidate/avatar/change`,
    method: 'post',
    data: data
  })
}
/**
 * 考生确认
 * @returns
 */
function candidateConfirm(data) {
  return request({
    url: `${config.EXAM}/candidate/confirm?${data}`,
    method: 'get'
  })
}
// 考生信息
function memberInfo() {
  return request({
    url: `${config.EXAM}/candidate/info`,
    method: 'get'
  })
}
/**
 * 获取考试信息
 */
function getCandidateInfo(candidateUuid) {
  return request({
    url: `${config.EXAM}/candidate/invite/${candidateUuid}`,
    method: 'get'
  })
}
/**
考生登陆
*/
function login(data) {
  return request({
    url: `${config.EXAM}/candidate/login`,
    method: 'post',
    data: data
  })
}
// 更新考生信息
function memberInfoUpdate(data) {
  return request({
    url: `${config.EXAM}/candidate/update`,
    method: 'post',
    data
  })
}
// 获取图片验证码
function imageValidationCode() {
  return request({
    url: `${config.EXAM}/candidate/validate_code`,
    method: 'get'
  })
}
// 登录下一场考试
function nextLogin() {
  return request({
    url: `${config.EXAM}/candidate/next/login`,
    method: 'POST'
  })
}

// 根据examId获取token
// post
function getExamToken(data) {
  return request({
    url: `${config.EXAM}/candidate/ct/login`,
    method: 'post',
    data
  })
}
export default {
  imageValidationCode,
  login,
  memberInfo,
  memberInfoUpdate,
  avatarUpdate,
  getCandidateInfo,
  candidateConfirm,
  nextLogin,
  getExamToken
}
