<!--
 * @Author: Libra
 * @Date: 2021-05-21 17:03:53
 * @LastEditTime: 2022-11-15 15:19:48
 * @LastEditors: Libra
 * @Description: 客服系统
 * @FilePath: /stone-exam-ui/src/components/Customer/index.vue
-->
<template>
  <div class="dialogue-main">
    <el-dialog
      title="常见问题回答"
      :visible.sync="centerDialogVisible"
      width="900px"
      :modal="false"
      custom-class="custom-dialog"
      center
    >
      <el-tabs stretch v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          :label="item.title"
          :name="item.index"
          v-for="(item, index) in QA"
          :key="index"
        >
          <div class="questions" v-for="(i, idx) in item.question" :key="idx">
            <div class="question"><i class="ball red"></i>问：{{ i.Q }}</div>
            <div class="answer"><i class="ball blue"></i>答：{{ i.A }}</div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <div class="dialogue-header">
      <div class="dialogue-service-info">
        <div class="dialogue-service-title">
          <div style="font-size: 14px; color: #1e1e1e">在线咨询</div>
        </div>
      </div>
      <div>
        <span class="common-question" @click="getProblem">常见问题</span>
        <i
          class="iconfont iconguanbi1"
          style="cursor:pointer;"
          @click="closeCustomer"
        ></i>
      </div>
    </div>
    <div id="dialogue_contain" class="dialogue-contain">
      <div class="message" v-for="(item, index) in messageList" :key="index">
        <p class="dialogue-customer-contain" v-if="item.type === 1">
          <span
            class="dialogue-text dialogue-customer-text"
            v-html="item.content"
          >
          </span>
        </p>
        <p class="dialogue-service-contain" v-else>
          <span
            class="dialogue-text dialogue-service-text"
            v-html="item.content"
          >
          </span>
        </p>
      </div>
    </div>
    <div class="dialogue-submit">
      <div class="top">
        <el-select v-model="category" placeholder="请选择问题类型">
          <el-option
            v-for="item in dropMenuList"
            :key="item.value"
            :label="`${item.label}问题`"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="bottom">
        <textarea
          id="dialogue_input"
          class="dialogue-input-text"
          v-model="input"
          @keyup.enter.exact="sendMessage"
          @keyup.shift.enter="lineFeed()"
          placeholder="请输入您的问题。。。"
        ></textarea>
        <div @click="sendMessage" class="dialogue-input-tools">
          发送
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QA from '@/common/qa.js'
import Api from '@/api/api'
import { bus } from '@/utils/bus'
export default {
  props: ['isOpen'],
  data() {
    return {
      centerDialogVisible: false,
      QA: QA,
      input: '',
      messageList: [],
      category: null,
      activeName: '0',
      dropMenuList: [
        {
          value: 1,
          label: '登录'
        },
        {
          value: 2,
          label: '摄像头'
        },
        {
          value: 3,
          label: '系统使用'
        },
        {
          value: 4,
          label: '网络卡顿'
        },
        {
          value: 5,
          label: '考题'
        },
        // {
        //   value: 6,
        //   label: '编程题'
        // },
        {
          value: 7,
          label: '其他'
        },
        {
          value: 8,
          label: '人脸身份验证'
        }
      ]
    }
  },
  created() {
    window.getProblem = this.getProblem
    this.getMessageList()
  },
  mounted() {
    this.getMessageListMqtt()
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    },
    getProblem() {
      this.activeName = this.category
        ? this.category > 5
          ? String(this.category - 2)
          : String(this.category - 1)
        : '0'
      this.centerDialogVisible = true
    },
    closeCustomer() {
      this.$emit('closeCustomer')
    },
    // 换行
    lineFeed() {
      this.input = this.input + '\n'
    },
    /**
    * LOGIN(1, "登录"),
      CAMERA(2, "摄像头"),
      GENERAL(3, "系统使用"),
      NETWORKS(4, "网络卡顿"),
      QUESTION(5, "考题"),
      CODE(6, "编程题"),
      OTHER(7, "其他"),
    */
    async sendMessage() {
      if (!this.input) {
        this.$message.error('请输入问题内容！')
        return
      }
      this.messageList.push({
        content: this.input,
        type: 1
      })
      if (this.messageList.length === 1) {
        this.messageList.push({
          content: `考生您好，您的提问已经收到，正排队处理中，为节省您的宝贵时间，建议您先查看<span style="color: #f35a5a;  font-weight: bold; cursor: pointer;" onclick="getProblem()">常见问题</span>回答！`,
          type: 0,
          isRead: true
        })
      }
      const res = await Api.sendMessage({
        content: this.input,
        category: this.category
      })
      await this.messageRead()
      if (res.code === 0) {
        this.input = ''
        this.scrollToBottom()
      } else {
        console.log('发送失败！！')
      }
    },
    getMessageListMqtt() {
      bus.$on('message', (message) => {
        if (message.type === 2) {
          this.messageList.push({
            ...message,
            isRead: null
          })
          this.scrollToBottom()
          this.$emit('messageList', this.messageList)
        }
      })
    },
    async getMessageList() {
      const res = await Api.getMessageList({})
      // 如果窗口打开着呢，就把所有消息置为已读
      if (this.isOpen) {
        await this.messageRead()
      }
      if (res.code === 0) {
        this.messageList = res.data || []
        if (this.messageList.length >= 1) {
          this.messageList.splice(1, 0, {
            content: `考生您好，您的提问已经收到，正排队处理中，为节省您的宝贵时间，建议您先查看<span style="color: #f35a5a;  font-weight: bold; cursor: pointer;" onclick="getProblem()">常见问题</span>回答！`,
            type: 0,
            isRead: true
          })
        }
        this.scrollToBottom()
      } else {
        console.log('获取失败！！')
      }
    },
    async messageRead() {
      await Api.messageRead({})
    },
    messageSendlisten(e) {
      if (!e.shiftKey && e.keyCode === 13) {
        e.cancelBubble = true // ie阻止冒泡行为
        e.stopPropagation() // Firefox阻止冒泡行为
        e.preventDefault() // 取消事件的默认动作*换行
        this.sendMessage()
      }
    },
    scrollToBottom() {
      const domWrapper = document.querySelector('.dialogue-contain') // 外层容器 出现滚动条的dom
      ;(function smoothscroll() {
        const currentScroll = domWrapper.scrollTop // 已经被卷掉的高度
        const clientHeight = domWrapper.offsetHeight // 容器高度
        const scrollHeight = domWrapper.scrollHeight // 内容总高度
        if (scrollHeight - 10 > currentScroll + clientHeight) {
          window.requestAnimationFrame(smoothscroll)
          domWrapper.scrollTo(
            0,
            currentScroll +
              10 +
              (scrollHeight - currentScroll - clientHeight) / 2
          )
        }
      })()
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
