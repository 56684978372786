var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialogue-main" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "常见问题回答",
            visible: _vm.centerDialogVisible,
            width: "900px",
            modal: false,
            "custom-class": "custom-dialog",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.centerDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            {
              attrs: { stretch: "" },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            _vm._l(_vm.QA, function (item, index) {
              return _c(
                "el-tab-pane",
                { key: index, attrs: { label: item.title, name: item.index } },
                _vm._l(item.question, function (i, idx) {
                  return _c("div", { key: idx, staticClass: "questions" }, [
                    _c("div", { staticClass: "question" }, [
                      _c("i", { staticClass: "ball red" }),
                      _vm._v("问：" + _vm._s(i.Q)),
                    ]),
                    _c("div", { staticClass: "answer" }, [
                      _c("i", { staticClass: "ball blue" }),
                      _vm._v("答：" + _vm._s(i.A)),
                    ]),
                  ])
                }),
                0
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "dialogue-header" }, [
        _vm._m(0),
        _c("div", [
          _c(
            "span",
            { staticClass: "common-question", on: { click: _vm.getProblem } },
            [_vm._v("常见问题")]
          ),
          _c("i", {
            staticClass: "iconfont iconguanbi1",
            staticStyle: { cursor: "pointer" },
            on: { click: _vm.closeCustomer },
          }),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "dialogue-contain", attrs: { id: "dialogue_contain" } },
        _vm._l(_vm.messageList, function (item, index) {
          return _c("div", { key: index, staticClass: "message" }, [
            item.type === 1
              ? _c("p", { staticClass: "dialogue-customer-contain" }, [
                  _c("span", {
                    staticClass: "dialogue-text dialogue-customer-text",
                    domProps: { innerHTML: _vm._s(item.content) },
                  }),
                ])
              : _c("p", { staticClass: "dialogue-service-contain" }, [
                  _c("span", {
                    staticClass: "dialogue-text dialogue-service-text",
                    domProps: { innerHTML: _vm._s(item.content) },
                  }),
                ]),
          ])
        }),
        0
      ),
      _c("div", { staticClass: "dialogue-submit" }, [
        _c(
          "div",
          { staticClass: "top" },
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "请选择问题类型" },
                model: {
                  value: _vm.category,
                  callback: function ($$v) {
                    _vm.category = $$v
                  },
                  expression: "category",
                },
              },
              _vm._l(_vm.dropMenuList, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: `${item.label}问题`, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "bottom" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.input,
                expression: "input",
              },
            ],
            staticClass: "dialogue-input-text",
            attrs: {
              id: "dialogue_input",
              placeholder: "请输入您的问题。。。",
            },
            domProps: { value: _vm.input },
            on: {
              keyup: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  if (
                    $event.ctrlKey ||
                    $event.shiftKey ||
                    $event.altKey ||
                    $event.metaKey
                  )
                    return null
                  return _vm.sendMessage.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  if (!$event.shiftKey) return null
                  return _vm.lineFeed()
                },
              ],
              input: function ($event) {
                if ($event.target.composing) return
                _vm.input = $event.target.value
              },
            },
          }),
          _c(
            "div",
            {
              staticClass: "dialogue-input-tools",
              on: { click: _vm.sendMessage },
            },
            [_vm._v(" 发送 ")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "dialogue-service-info" }, [
      _c("div", { staticClass: "dialogue-service-title" }, [
        _c("div", { staticStyle: { "font-size": "14px", color: "#1e1e1e" } }, [
          _vm._v("在线咨询"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }