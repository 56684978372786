<!--
 * @Author: Libra
 * @Date: 2021-05-24 17:51:11
 * @LastEditTime: 2024-04-18 11:15:24
 * @LastEditors: Libra
 * @Description: App.vue
 * @FilePath: /stone-exam-ui/src/App.vue
-->
<template>
  <div id="app">
    <div class="mobile" v-if="isMobile">
      <i class="iconfont icondiannaoban"></i>
      <span class="title">请使用电脑作答！ </span>
      <span class="content">
        考生您好！为了实现更好的系统操作体验，请使用电脑作答此考试！
      </span>
    </div>
    <div v-else>
      <layout v-if="$store.state.token&&$route.name&&$route.name !== 'Login'&& $route.name !== 'Telecom'">
          <transition name="fade" mode="out-in">
            <router-view :key="key" />
          </transition>
      </layout>
      <transition name="fade" mode="out-in" v-else>
        <router-view :key="key" />
      </transition>
    </div>
  </div>
</template>
<script>
import layout from '@/layout/index.vue'
export default {
  name: 'Layout',
  data() {
    return {
      isMobile: false
    }
  },
  components: {
    layout
  },
  mounted() {
    // 金庸浏览器前进后退
    window.onpopstate = () => history.go(1)
    // 禁用鼠标右键
    document.oncontextmenu = e => e.preventDefault()
  },
  computed: {
    key: {
      get() {
        return this.$route.path
      }
    }
  }
}
</script>
<style scoped lang="scss">
#app {
  font-family: PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  position: relative;
}

.mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-top: 20px;
  padding: 20px;
  .iconfont {
    font-size: 120px;
    color: #f35a5a;
  }
  .title {
    font-size: 20px;
    font-weight: bold;
    color: #f35a5a;
  }
  .content {
    font-size: 14px;
    color: #999;
    margin-top: 20px;
  }
}
</style>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
