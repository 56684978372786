<!--
 * @Author: Libra
 * @Date: 2021-05-17 14:57:57
 * @LastEditTime: 2022-11-15 15:14:16
 * @LastEditors: Libra
 * @Description:最新消息的底部弹窗
 * @FilePath: /stone-exam-ui/src/components/NewMessage/index.vue
-->
<template>
  <div class="msg-container" v-if="show">
    <i class="iconfont iconguanbi1" @click="close()"></i>
    <div class="msg-con">
      <div class="msg-content-container" ref="msgContainer">
        <i class="iconfont iconiconfontzhizuobiaozhun41"></i>
        <div class="msg-content">
          {{ content }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: String
  },
  data() {
    return {
      show: false,
      timer: null
    }
  },
  methods: {
    close() {
      this.show = false
      clearInterval(this.timer)
    },
    open() {
      clearInterval(this.timer)
      this.show = true
      this.$nextTick(() => {
        this.scroll()
      })
    },
    scroll() {
      const container = this.$refs.msgContainer
      const doc = document.documentElement
      const containerWidth = container.offsetWidth
      const docWidth = doc.offsetWidth
      container.style.left = docWidth + 'px'
      let offset = docWidth
      this.timer = setInterval(() => {
        offset -= 0.5
        container.style.left = offset + 'px'
        if (offset <= 0 && Math.abs(offset) === containerWidth) {
          offset = docWidth
        }
      }, 5)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(this.timer)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
