<!--
 * @Author: Libra
 * @Date: 2021-04-27 10:59:54
 * @LastEditors: Libra
 * @Description: 头部组件
 * @FilePath: /stone-exam-ui/src/components/simpleHeader/index.vue
-->
<template>
  <div
class="header"
:style="$route.name === 'Question'
    ? 'box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);'
    : ''
    ">
    <GlobalDialog
:dialogVisible="showSubmitingDialog"
@dialog-cancel="showSubmitingDialog = false"
title="交卷中"
      width="500px"
:isShowFooter="false"
:show_close="false">
      <div class="camera-snap-container">
        <div class="content">
          <i class="iconfont icontishi"></i>
          <div class="right">
            <span class="title">正在交卷中....</span>
            <span class="detail">请稍后！</span>
          </div>
        </div>
      </div>
    </GlobalDialog>
    <GlobalDialog :dialogVisible="showRemainMinutes" @dialog-cancel="showRemainMinutes = false" title="提示" width="500px">
      <div class="camera-snap-container">
        <div class="content">
          <i class="iconfont icontishi"></i>
          <div class="right">
            <span class="title">作答时间剩余<span style="color: #f35a5a; font-size: 30px">
                5 </span>分钟</span>
            <span class="detail">请掌控答题进度，及时交卷！</span>
          </div>
        </div>
      </div>
    </GlobalDialog>
    <GlobalDialog
:dialogVisible="showScreenShare"
title="录屏共享桌面提示"
width="600px"
@dialog-cancel="screenShare"
      :show_close="false"
btn_title="开始共享屏幕">
      <div class="camera-snap-container">
        <div class="content screen-share">
          <span class="title">* 本场考试要求录制您的电脑，请根据浏览器提示共享您的电脑,
            关闭录屏将会影响您的成绩。</span>
          <img
src="@/assets/images/screenShare.jpeg"
alt=""
            style="width: 500px; height: auto; top: 0; transform: translate(0)" />
        </div>
      </div>
    </GlobalDialog>
    <GlobalDialog
:dialogVisible="showPhoneDetectError"
@dialog-cancel="closeQRErr"
width="500px"
:show_close="false"
      title="提示">
      <div class="qr-tip">
        <div class="qr-container">
          <vue-qr
:size="800"
style="
              width: 150px;
              height: 150px;
              transform: translateY(0);
              max-width: 300px;
            "
:text="pagePath"></vue-qr>
          <div class="refresh-qr" v-if="isFailure">
            <span class="failure">二维码已失效</span>
            <el-button type="danger" @click="refresh">请点击刷新</el-button>
          </div>
        </div>
        <div class="right">
          <span class="title red">
            <div>手机端视频监控异常断开</div>
          </span>
          <span class="title">请用微信扫码重连，15秒后状态恢复正常</span>
          <span class="content">未开启视频监控，会影响考试结果。请关闭手机自动息屏，保持手机常亮！
          </span>
        </div>
      </div>
    </GlobalDialog>
    <div class="header-container" :style="isProgram ? 'width: 100%;padding: 0 20px' : 'width: 1230px'">
      <div class="img-contaienr">
        <img class="logo" v-if="logo" :src="logo" alt="企业LOGO" />
        <img class="logo" v-else-if="logo === null" src="../../assets/images/logo_igp.png" alt="企业LOGO" />
        <span v-else-if="logo === undefined"> </span>
      </div>
      <div v-if="hasRight" class="right">
        <div
class="detect-container pc-detect"
@click="showScreenShare = true"
v-if="$store.state.showPc"
          :style="{ color: $store.state.pcDetect ? '#666' : '#cb2a1d' }">
          <i class="iconfont icondiannaoban"></i>
          <span class="detecting">{{
            $store.state.pcDetect ? "正在监控" : "监控异常"
          }}</span>
        </div>
        <div
class="detect-container mobile-detect"
v-if="$store.state.showMobile && !showMobileIcon"
          @mouseenter="displayQr"
@mouseleave="showQr = false"
          :style="{ color: $store.state.mobileDetect ? '#666' : '#cb2a1d' }">
          <div v-if="showQr" class="qr">
            <vue-qr :size="800" style="width: 200px; height: 160px" :text="pagePath"></vue-qr>
          </div>
          <i class="iconfont iconshouji01"></i>
          <span class="detecting">{{
            initMobile
            ? "未开启"
            : $store.state.mobileDetect
              ? "正在监控"
              : "监控异常"
          }}</span>
        </div>
        <div
class="detect-container"
v-if="$store.state.showCamera"
          :style="{ color: $store.state.cameraDetect ? '#666' : '#cb2a1d' }">
          <i class="iconfont iconshexiangtou2" v-if="!$store.state.cameraDetect"></i>
          <svg v-else width="127" height="75" viewBox="0 0 127 151" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="shexiangtou 1">
              <path
id="Vector"
                d="M63.0996 126.199C28.3211 126.199 0 97.8781 0 63.0996C0 28.3211 28.3211 0 63.0996 0C97.8781 0 126.2 28.3209 126.2 63.0994C126.2 97.8779 97.8785 126.199 63.1 126.199L63.0996 126.199ZM63.0996 12.7111C35.3379 12.7111 12.7113 35.2869 12.7113 63.0996C12.7113 90.9123 35.2869 113.488 63.0996 113.488C90.9123 113.488 113.488 90.9123 113.488 63.0996C113.488 35.2869 90.8617 12.7113 63.0996 12.7113V12.7111Z"
                fill="#666" />
              <path
id="Rectangle 2"
d="M56 113C56 113 58.5 113.5 63 113.5C67.5 113.5 70 113 70 113V141H56V113Z"
                fill="#666" />
              <path id="Vector_2" d="M29.7447 138.097H96.4037V150.809H29.7447V138.097Z" fill="#666" />
              <circle id="cameras" cx="64" cy="63" r="14" fill="#666" />
            </g>
          </svg>
          <span class="detecting">{{
            $store.state.cameraDetect ? "正在监控" : "监控异常"
          }}</span>
        </div>
        <div class="count-down-part-container" v-if="isInPart && isPartTime">
          <span class="count-down-title">{{ "小卷倒计时" }}</span>
          <span class="count-down">{{ String(hourPart).padStart(2, "0") }}:{{
            String(minutePart).padStart(2, "0")
          }}:{{ String(secondPart).padStart(2, "0") }}</span>
        </div>
        <div class="count-down-container" v-if="!isInPart || !isPartTime">
          <span class="count-down-title">{{
            flag ? "距考试结束" : "开考倒计时"
          }}</span>
          <div>
            <span class="count-down" v-if="day <= 0">{{ String(hour).padStart(2, "0") }}:{{
              String(minute).padStart(2, "0")
            }}:{{ String(second).padStart(2, "0") }}</span>
            <span class="count-down" v-else>{{ day }} <span style="font-size: 14px">天</span></span>
          </div>
        </div>
        <div class="personal-info-container">
          <div
class="icon-container"
ref="avatar"
:style="$store.state.showRedCard
            ? 'border: 2px solid #D95154'
            : $store.state.showYellowCard
              ? 'border: 2px solid #FFDE3C'
              : 'border: 1px solid rgb(155, 155, 155)'
            ">
            <i v-if="!$store.state.userInfo.avatar" class="iconfont iconyonghu8"></i>
            <img
v-else
style="width: 60px; height: 60px"
              :src="`${file_host}${$store.state.userInfo.avatar}?${avatarRandom}`"
alt="" />
          </div>
          <div class="info-right">
            <span class="name">{{ $store.state.userInfo.realName }}</span>
            <div class="exit" @click="logout()">
              <i class="iconfont icontuichu"></i>
              <span class="exit-title">退出</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { removeToken } from '@/utils/auth'
import { getRandom } from '@/utils/common.js'
import GlobalDialog from '@/components/GlobalDialog'
import Api from '@/api/api'
import VueQr from 'vue-qr'
import whiteList from '@/common/whiteList'
import { clearAll, getItem } from '@/utils/storage'
import { file_host } from '@/api/config'
import store from '@/store'
import { bus } from '@/utils/bus'
import Worker from '@/worker/time.worker.js'
import partWorker from '@/worker/partTime.worker.js'
export default {
  name: 'SimpleHeader',
  props: {
    hasRight: {
      type: Boolean,
      default: true
    },
    isDetect: {
      type: Boolean,
      default: false
    },
    isProgram: {
      type: Boolean,
      default: false
    },
    companyInfo: {
      type: Object
    },
    time: {
      type: Object
    }
  },
  data() {
    return {
      isFlash: true,
      hour: 0,
      minute: 0,
      second: 0,
      hourPart: 0,
      minutePart: 0,
      secondPart: 0,
      resetSec: 0,
      pagePath: '',
      file_host: file_host,
      isInPart: false,
      randomSubTime: 0,
      /**
       * timeRule: 1 按总时长计时 2 按子卷时长计时
       * partOpenType: 1 按顺序打开 2 随意顺序打开
       */
      isPartTime: this.$store.state.jobInfo.timeRule === 2,
      flag: false,
      day: 0,
      showRemainMinutes: false,
      showScreenShare: false,
      showPhoneDetectError: false,
      isFailure: false,
      logo: undefined,
      showQr: false,
      qrTimer: null,
      isStart: null,
      mobileMonitor: this.$store.state.examInfo.mobileMonitorType === 0,
      initMobile: true,
      frontRouteName: ['ConfirmBasic', 'ConfirmCamera', 'FaceRecognition'],
      showMobileIcon: false,
      avatarRandom: Math.random(),
      partTime: 0,
      // 是否还有下一场考试
      hasNextExam: !!this.$store.state.examInfo.nextExamUuid,
      timer: null,
      worker: null,
      partWorker: null,
      showSubmitingDialog: false
    }
  },
  components: {
    GlobalDialog,
    VueQr
  },
  created() {
    this.getLogo()
  },
  beforeDestroy() {
    this.worker.postMessage('stop')
    bus.$off('extendTime')
    this.worker.terminate()
    this.partWorker.terminate()
  },
  watch: {
    companyInfo() {
      const shortId = this.$route.query.shortId
      if (!shortId) {
        this.getLogoWithToken()
      }
    },
    '$store.state.userInfo.startedAt': {
      handler(val) {
        if (val) {
          this.isStart = true
        }
      },
      immediate: true,
      deep: true
    },
    // 路由
    $route(to, from) {
      if (to.name === 'Question') {
        this.isInPart = this.$route.name === 'Question' && this.isStart
      } else if (to.name === 'PhoneMonitor') {
        this.isInPart = false
        this.showMobileIcon = false
      } else {
        this.isInPart = false
      }
      // 从 confirmExam 跳转到其他Question页面
      if (from.name === 'ConfirmExam') {
        this.handlePartExamCountDown()
      }
      if (from.name === 'Question' && to.name === 'ConfirmExam') {
        this.partWorker.postMessage('stop')
      }
    }
  },
  async mounted() {
    bus.$on('extendTime', (val) => {
      this.resetSec = this.resetSec + Number(val) * 60 * 1000
    })
    this.worker = new Worker()
    this.partWorker = new Worker(partWorker)
    this.isStart = this.$store.state.userInfo.startedAt
    this.showMobileIcon = this.frontRouteName.includes(this.$route.name)
    const { examType, mobileMonitorType, mobileVideoType } =
      this.$store.state.examInfo
    this.getMobileDetectStatus(mobileMonitorType, mobileVideoType)
    // 是否是随来随考
    const isStartExamAtMoment = examType === 2
    this.init(isStartExamAtMoment)
  },
  methods: {
    logout() {
      // 清除token
      removeToken()
      // 判断是邀请作答还是公开作答
      const isPublic = this.$store.state.examInfo.fromType === 2
      const shortId = getItem('shortId')
      const exam = getItem('exam')
      location.href = `/#/${isPublic ? 'publiclogin' : 'login'
      }?shortId=${shortId}&${exam ? `exam=${exam}` : ''}`
      clearAll()
    },
    async getMobileDetectStatus(mobileMonitorType, mobileVideoType) {
      if (whiteList.includes(this.$route.name)) return
      this.getMobileDetectStatusMqtt()
      const { commit, state } = this.$store
      if (mobileMonitorType !== 0) {
        const res = await Api.getOccupyStatus()
        if (res.code === 0) {
          if (res.data.isHolding !== null) {
            const isHolding = res.data.isHolding
            const duration = res.data.duration
            this.initMobile = false
            commit('setShowMobile', true)
            if (duration > 30) {
              commit('setMobileDetect', false)
              if (state.mobileDetect) {
                this.showMobileError()
              }
            } else {
              commit('setMobileDetect', isHolding)
              if (!isHolding) {
                if (state.mobileDetect) {
                  this.showMobileError()
                }
              }
            }
          } else {
            commit('setShowMobile', true)
            commit('setMobileDetect', false)
            this.initMobile = true
          }
        }
        return
      }
      if (mobileVideoType !== 0) {
        const res = await Api.checkMobile()
        if (res.code === 0) {
          if (res.data === null) {
            commit('setShowMobile', true)
            commit('setMobileDetect', false)
            this.initMobile = true
            return
          }
          if (!res.data) {
            this.initMobile = false
            if (state.mobileDetect) {
              this.showMobileError()
            }
            commit('setShowMobile', true)
            commit('setMobileDetect', false)
          } else {
            this.initMobile = false
            commit('setShowMobile', true)
            commit('setMobileDetect', true)
          }
        }
      }
    },
    getMobileDetectStatusMqtt() {
      const { commit } = this.$store
      bus.$on('message', (message) => {
        switch (message.type) {
          case 10:
            this.initMobile = false
            commit('setShowMobile', true)
            commit('setMobileDetect', true)
            break
          case 11:
            this.initMobile = false
            this.showMobileError()
            commit('setShowMobile', true)
            commit('setMobileDetect', false)
            break
          case 12:
            this.initMobile = false
            if (message.content === 'online') {
              commit('setShowMobile', true)
              commit('setMobileDetect', true)
            } else if (message.content === 'offline') {
              commit('setShowMobile', true)
              commit('setMobileDetect', false)
            }
            break
        }
      })
    },
    async init(isStartExamAtMoment) {
      if (whiteList.includes(this.$route.name)) return
      // 是否是测试账号
      let isTestAccount = false
      const { userInfo } = this.$store.state
      isTestAccount = userInfo.type === 2
      const res = await Api.getTime()
      const time = res.data
      this.isStart = time.candidateStartedAt !== null
      this.isInPart = this.$route.name === 'Question' && this.isStart
      this.randomSubTime = getRandom(5, 20)
      this.resetSec = time.secondToStart * 1000
      // 刷新页面
      if (this.$route.name === 'Question') {
        this.handlePartExamCountDown()
      }
      // 用来判断 resetSec 是 secondToStart 还是 secondRemain
      this.flag = false
      const { commit } = this.$store
      commit('setPractice', true)
      this.worker.postMessage('start')
      this.worker.onmessage = (e) => {
        this.examCountDown(this.resetSec)
        this.showRemainMinutesDialog()
        this.autoFinish()
        if (this.resetSec <= 0 && !this.flag) {
          // 到考试时间了，随来随考，考生没有点击开始考试，就要显示00:00:00
          if (!this.isStart && isStartExamAtMoment) {
            this.resetSec = 0
          } else {
            this.flag = true
            this.resetSec = time.secondRemain * 1000
          }
          commit('setPractice', false)
        }
        this.autoToNotice(isTestAccount)
      }
      // await this.calibrationTime()
    },
    // 练习过程中如果到开考时间了，就自动跳转到考试开始前的页面
    async autoToNotice(isTestAccount) {
      const routeName = this.$route.name
      const { commit } = this.$store
      // 但如果是测试账号则不需要，因为测试账号不需要练习题
      if (!isTestAccount) {
        if (this.resetSec === 10 * 1000 && !this.flag) {
          commit('setPractice', false)
          if (routeName === 'Question' || routeName === 'ConfirmExam') {
            this.$router.push({ path: '/notice' })
          }
        }
      } else {
        commit('setPractice', false)
      }
    },
    // 处理小卷倒计时
    async handlePartExamCountDown() {
      if (this.isInPart && this.isPartTime) {
        await this.getPartTime()
        // this.partCalibrationTime()
        this.partWorker.postMessage('start')
        this.partWorker.onmessage = (e) => {
          this.partExamCountDown(this.partTime)
        }
      }
    },
    partExamCountDown(time) {
      this.hourPart = parseInt((time / (60 * 60)) % 24)
      this.minutePart = parseInt((time / 60) % 60)
      this.secondPart = parseInt(time % 60)
      // 小卷时间到了，帮他保存答案，并提交小卷，然后跳转到小卷列表页
      if (time <= 0) {
        bus.$emit('saveCode', false)
        setTimeout(() => {
          bus.$emit('submit')
        }, 500)
        this.partWorker.postMessage('stop')
        return
      }
      this.partTime--
    },
    async getPartTime() {
      const { partUuid } = this.$route.query
      if (!this.isStart || !partUuid) return
      const res = await Api.getPartTime(partUuid)
      if (res.code === 0) {
        const resData = res.data
        this.partTime = resData
      }
    },
    // 开始倒计时
    async examCountDown() {
      if (this.resetSec <= 0) {
        this.resetSec = 0
        return
      }
      this.resetSec -= 1000
      this.day = parseInt(this.resetSec / (60 * 60 * 24 * 1000))
      this.hour = parseInt((this.resetSec / (60 * 60 * 1000)) % 24)
      this.minute = parseInt((this.resetSec / (60 * 1000)) % 60)
      this.second = parseInt((this.resetSec / 1000) % 60)
    },
    // 显示还剩五分钟弹框
    showRemainMinutesDialog() {
      if (this.resetSec === 300000 && this.flag) {
        this.showRemainMinutes = true
        // 5秒后自动关闭
        setTimeout(() => {
          this.showRemainMinutes = false
        }, 5000)
      }
      if (this.isPartTime) return
      // 只获取一次DOM结构即可，目前没时间，待优化。 TODO
      if (this.resetSec <= 300000 && this.flag) {
        const countDownContainer = document.querySelector(
          '.count-down-container'
        )
        const countDown = document.querySelector('.count-down')
        countDownContainer.style.backgroundColor = '#f35a5a'
        countDownContainer.style.color = '#fff'
        countDown.style.color = '#fff'
      }
    },
    // 考试结束前5到10秒自动交卷
    async autoFinish() {
      if (this.resetSec === 0 && this.$route.name !== 'Complete' && this.flag) {
        this.worker.postMessage('stop')
        // 没有进入试题，但考试时间已经结束了
        if (!this.$store.state.userInfo.startedAt) {
          if (this.hasNextExam) {
            await this.nextExam()
          } else {
            // removeToken()
            this.$router.push('/complete')
          }
          return
        }
        // 考试时间结束，要主动帮学生调一下saveAnswer，防止没有保存
        if (this.$route.name === 'Question') {
          console.log('交卷自动保存答案')
          bus.$emit('saveCode', false)
          await this.partFinish()
        }
        this.showSubmitingDialog = true
        setTimeout(async() => {
          await this.examFinish()
          this.showSubmitingDialog = false
        }, this.randomSubTime * 1000)
      }
    },
    // 进入下一场考试
    async nextExam() {
      const res = await Api.nextLogin()
      if (res.code === 0) {
        store.commit('setToken', res.data)
        this.$router.push('/basic')
      }
    },
    // 考试结束
    async examFinish() {
      const res = await Api.examFinish()
      if (res.code === 0) {
        if (this.hasNextExam) {
          await this.nextExam()
        } else {
          // removeToken()
          this.$router.push('/complete')
          console.log('自动交卷成功！！')
        }
      } else {
        // removeToken()
        this.$router.push('/complete')
        console.log('自动交卷失败！！')
      }
    },
    async partFinish() {
      const res = await Api.partFinish(this.$route.query.partUuid)
      if (res.code === 0) {
        this.$router.push('/exam')
      }
    },
    async showMobileError() {
      this.refreshQR()
      await this.getMobileToken()
      this.showPhoneDetectError = true
    },
    closeQRErr() {
      this.showPhoneDetectError = false
      clearTimeout(this.qrTimer)
    },
    async getLogo() {
      const shortId = this.$route.query.shortId || getItem('shortId')
      if (!shortId || shortId === 'undefined') {
        this.logo = null
        return
      } else {
        const res = await Api.getNameAndLogo({
          examShortId: shortId
        })
        if (res.code === 0) {
          const { commit } = this.$store
          commit('setCompanyUuid', res.data.companyUuid)
          if (!res.data.companyLogo) {
            this.logo = null
            return
          }
          this.logo =
            this.file_host + res.data.companyLogo + '?' + Math.random()
        }
      }
    },
    async getLogoWithToken() {
      const { companyLogo, companyShortName } = this.companyInfo
      const { scenario } = this.$store.state.examInfo
      this.logo =
        companyLogo === null
          ? null
          : this.file_host + companyLogo + '?' + Math.random()
      document.title = companyShortName
        ? companyShortName + scenario + '系统-国考云'
        : '国考云在线考试'
    },
    async getMobileToken() {
      const res = await Api.getMobileToken()
      if (res.code === 0) {
        // const env = 'beta'
        const env = process.env.NODE_ENV
        const host = 'https://exam.stac.fun?token='
        this.pagePath = !this.mobileMonitor
          ? `${host}${res.data}&Mtype=0&env=${env}`
          : `${host}${res.data}&Mtype=1&env=${env}`
        // this.pagePath = `https://exam.stac.fun/mobileVideo/${res.data}`
      }
    },
    async displayQr() {
      this.refreshQR()
      await this.getMobileToken()
      this.showQr = true
      setTimeout(() => {
        this.showQr = false
      }, 20000)
    },
    refreshQR() {
      this.qrTimer = setTimeout(() => {
        this.isFailure = true
        clearTimeout(this.qrTimer)
      }, 60000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(this.qrTimer)
      })
    },
    async refresh() {
      await this.getMobileToken()
      this.isFailure = false
      this.refreshQR()
    },
    // 时间校正
    async calibrationTime() {
      const twoMin = 1000 * 60 * 30
      // const twoMin = 3000
      const t = setInterval(async() => {
        const time = await Api.getTime()
        const { secondToStart, secondRemain } = time.data
        this.resetSec = this.flag ? secondRemain * 1000 : secondToStart * 1000
        // this.resetSec = 20 * 1000
        console.log('两分钟时间同步校对')
        // clearInterval(t)
      }, twoMin)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(t)
      })
    },
    // 小卷计时时间校正
    async partCalibrationTime() {
      if (!this.$route.query.partUuid) {
        clearInterval(t)
        return
      }
      const twoMin = 1000 * 60 * 30
      const t = setInterval(async() => {
        if (!this.$route.query.partUuid) {
          clearInterval(t)
          return
        }
        const res = await Api.getPartTime(this.$route.query.partUuid)
        if (res.code === 0) {
          this.partTime = res.data
        }
        console.log('两分钟小卷计时时间同步校对')
      }, twoMin)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(t)
      })
    },
    screenShare() {
      this.showScreenShare = false
      bus.$emit('screenShare')
    }
  }
}
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
