var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "GlobalDialog",
        {
          attrs: {
            dialogVisible: _vm.showMqttDisConnect,
            title: "提示",
            btn_title: "重新连接",
            width: "500px",
          },
          on: { "dialog-cancel": _vm.reConnectMqtt },
        },
        [
          _c("div", { staticClass: "camera-snap-container" }, [
            _c("div", { staticClass: "content" }, [
              _c("i", { staticClass: "iconfont icontishi" }),
              _c("div", { staticClass: "right" }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v("检测到您断开连接"),
                ]),
                _c("span", { staticClass: "detail" }, [
                  _vm._v("请点击下方按钮重新尝试！！"),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }