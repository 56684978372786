var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c("div", { staticClass: "msg-container" }, [
        _c("i", {
          staticClass: "iconfont iconguanbi1",
          on: {
            click: function ($event) {
              return _vm.close()
            },
          },
        }),
        _c("div", { staticClass: "msg-con" }, [
          _c(
            "div",
            { ref: "msgContainer", staticClass: "msg-content-container" },
            [
              _c("i", { staticClass: "iconfont iconiconfontzhizuobiaozhun41" }),
              _c("div", { staticClass: "msg-content" }, [
                _vm._v(" " + _vm._s(_vm.content) + " "),
              ]),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }