var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "app" } }, [
    _vm.isMobile
      ? _c("div", { staticClass: "mobile" }, [
          _c("i", { staticClass: "iconfont icondiannaoban" }),
          _c("span", { staticClass: "title" }, [_vm._v("请使用电脑作答！ ")]),
          _c("span", { staticClass: "content" }, [
            _vm._v(
              " 考生您好！为了实现更好的系统操作体验，请使用电脑作答此考试！ "
            ),
          ]),
        ])
      : _c(
          "div",
          [
            _vm.$store.state.token &&
            _vm.$route.name &&
            _vm.$route.name !== "Login" &&
            _vm.$route.name !== "Telecom"
              ? _c(
                  "layout",
                  [
                    _c(
                      "transition",
                      { attrs: { name: "fade", mode: "out-in" } },
                      [_c("router-view", { key: _vm.key })],
                      1
                    ),
                  ],
                  1
                )
              : _c(
                  "transition",
                  { attrs: { name: "fade", mode: "out-in" } },
                  [_c("router-view", { key: _vm.key })],
                  1
                ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }