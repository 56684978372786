var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "modal-append-to-body": false,
        title: _vm.title,
        visible: _vm.visible,
        width: _vm.width,
        modal: _vm.showModal,
        "before-close": _vm.before_close,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "custom-class": _vm.customClass,
        "show-close": _vm.show_close,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c("div", { staticClass: "slot-container" }, [_vm._t("default")], 2),
      _vm.isShowFooter
        ? _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "known-button",
                  attrs: { type: "danger", plain: "", disabled: _vm.disabled },
                  on: { click: _vm.cancel },
                },
                [_vm._v(_vm._s(_vm.btn_title || "我知道了"))]
              ),
              _vm.showSecond
                ? _c(
                    "el-button",
                    {
                      staticClass: "known-button",
                      attrs: {
                        type: "danger",
                        plain: "",
                        disabled: _vm.disabled2,
                      },
                      on: { click: _vm.ok },
                    },
                    [_vm._v(_vm._s(_vm.btn_title2 || "我知道了"))]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }