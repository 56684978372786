/*
 * @Author: Libra
 * @Date: 2021-12-27 19:46:07
 * @LastEditTime: 2023-02-13 18:56:45
 * @LastEditors: Libra
 * @Description:rtc api
 * @FilePath: /stone-exam-ui/src/api/rtc/index.js
 */
import request from '@/utils/request'
import config from '../config'

// 获取 rtc token
export function getRtcToken() {
  return request({
    url: `${config.EXAM}/rtc/token`,
    method: 'get'
  })
}

// 云端录制  开始录制
export function startCloudRecord(data) {
  return request({
    url: `${config.EXAM}/rtc/start`,
    method: 'post',
    data
  })
}

// 云端录制  停止录制
export function stopCloudRecord(data) {
  return request({
    url: `${config.EXAM}/rtc/stop`,
    method: 'post',
    data
  })
}
// 删除视频
export function deleteCloudVideo(questionUuid) {
  return request({
    url: `${config.EXAM}/rtc/remove/${questionUuid}`,
    method: 'get'
  })
}
// 测试网速sig
function getTestSig() {
  return request({
    url: `${config.EXAM}/trtc/test/sig`,
    method: 'get'
  })
}
export default {
  getRtcToken,
  startCloudRecord,
  stopCloudRecord,
  deleteCloudVideo,
  getTestSig
}
